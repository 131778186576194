import useAuth from "@hooks/network/useAuth";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

function LogoutButton() {
  const { postSignout } = useAuth();
  const handleLogout = () => {
    postSignout();
  };

  return (
    <ListItem disablePadding>
      <ListItemButton sx={{ padding: 1 }} dense onClick={handleLogout}>
        <ListItemText>
          <Typography sx={{ fontSize: 14 }}>로그아웃</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

export default LogoutButton;
