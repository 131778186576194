import Box from "@mui/material/Box";
import { transformToKst } from "shared-utils";

type Props = {
  dateAt?: string;
};

export function DateTableData({ dateAt }: Props) {
  return (
    <Box sx={{ fontSize: 12 }}>{dateAt ? transformToKst(dateAt) : ""}</Box>
  );
}
