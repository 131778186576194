import { useMemo } from "react";

import { AccordionSummary } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { FieldValues, Path, useController } from "react-hook-form";
import { transformValToOriginalType } from "shared-utils";

import { BaseDropdownInputGroupProps } from "../../types";
import { BaseRadioGroup } from "../BaseRadioGroup";

export function BaseDropdownInputGroup<TFieldValues extends FieldValues>({
  control,
  name,
  options,
  label,
  helperText,
  expandTriggerValue,
  children,
  layoutType,
  size,
  disabled,
  error
}: BaseDropdownInputGroupProps<TFieldValues>) {
  const { field } = useController({
    control,
    name: name as Path<TFieldValues>
  });

  const optionType = useMemo(() => typeof options[0].value, [options]);
  const shouldExpand =
    typeof expandTriggerValue === "object"
      ? expandTriggerValue.includes(
          transformValToOriginalType(field.value, optionType)
        )
      : transformValToOriginalType(field.value, optionType) ===
        expandTriggerValue;

  return (
    <div>
      <Accordion expanded={shouldExpand}>
        <AccordionSummary sx={{ p: 0 }}>
          <BaseRadioGroup
            name={name}
            control={control}
            options={options}
            label={label}
            helperText={helperText}
            layoutType={layoutType}
            size={size}
            disabled={disabled}
            error={error}
          />
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: "none"
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  background: theme.palette.grey[50],
  margin: `0 ${theme.spacing(4)} ${theme.spacing(1)} ${theme.spacing(4)}`
}));
