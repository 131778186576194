import { useState, ReactElement } from "react";

import type { LayoutProps } from "types/pageWithLayouts.types";
import AppHeader from "@layouts/AppHeader";
import Sidebar from "@layouts/Sidebar";
import { getLayout as getSiteLayout } from "@layouts/SiteLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { drawerState } from "@store/recoil/drawerRecoil";
import { useRecoilValue } from "recoil";

interface Props {
  children: ReactElement;
}

const DashboardLayout: LayoutProps = ({ children }: Props) => {
  const drawerWidth = useRecoilValue(drawerState);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppHeader handleDrawerToggle={handleDrawerToggle} />
      <Sidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <StyledMain component="main" drawerWidth={drawerWidth}>
        {children}
      </StyledMain>
    </>
  );
};

type StyledMainProps = { drawerWidth: number };

const StyledMain = styled(Box, {
  shouldForwardProp: (prop) => prop !== "drawerWidth"
})<StyledMainProps>(({ theme, drawerWidth }) => ({
  background: theme.palette.grey[50],
  marginTop: "56px",
  minHeight: "calc(100vh - 56px)",
  flexGrow: 1,
  padding: "24px",
  width: `calc(100% - ${drawerWidth}px)`,
  [theme.breakpoints.up("sm")]: {
    marginTop: "64px",
    minHeight: "calc(100vh - 64px)"
  }
}));

export const getLayout = (page: ReactElement) =>
  getSiteLayout(<DashboardLayout>{page}</DashboardLayout>);

export default DashboardLayout;
