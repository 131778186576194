import TextField from "@mui/material/TextField";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import dayjs from "dayjs";
import { Controller, FieldValues, Path } from "react-hook-form";

import { BaseDayTimeProps } from "../../types";
import { FormInputWrapper } from "../FormInputWrapper/FormInputWrapper";

function BaseDayTimeInput<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  fullWidth,
  size,
  layoutType = "default",
  helperText,
  error,
  disabled,
  afterNow = false,
  minDateTime
}: BaseDayTimeProps<TFieldValues>) {
  return (
    <FormInputWrapper
      fullWidth={fullWidth}
      width={194}
      layoutType={layoutType}
      size={size}
      label={label}
    >
      <Controller
        name={name as Path<TFieldValues>}
        render={({ field }) => (
          <MobileDateTimePicker
            label={label}
            minDateTime={
              minDateTime ? minDateTime : afterNow ? dayjs() : undefined
            }
            onChange={(date) => field.onChange(date)}
            value={field.value}
            inputFormat="YYYY/MM/DD hh:mm a"
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size={size}
                error={error ? true : false}
                helperText={
                  (error?.message as string | undefined) ?? helperText
                }
              />
            )}
          />
        )}
        control={control}
      />
    </FormInputWrapper>
  );
}

export default BaseDayTimeInput;
