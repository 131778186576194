import { useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";

import {
  StyledLoadingWrapper,
  StyledThumnailImageWrapper,
  StyledZoomInIcon
} from "./styles";
import { ThumnailDialog } from "./ThumnailDialog";
type Props = {
  thumnailUrl?: string;
  fullImageUrl?: string;
  maxWidth?: "small" | "medium" | "large" | false;
  dialogMode?: boolean;
  zoomIcon?: boolean;
  isLoading?: boolean;
};

export function ThumnailPhotoTableData({
  thumnailUrl,
  fullImageUrl,
  maxWidth = "medium",
  dialogMode = true,
  zoomIcon = false,
  isLoading
}: Props) {
  const [open, setOpen] = useState(false);

  const openThumnailDialog = () => {
    setOpen(true);
  };

  const closeThumnailDialog = () => {
    setOpen(false);
  };

  const getWidth = (maxWidth: Props["maxWidth"]) => {
    if (maxWidth === "small") return "40";
    if (maxWidth === "medium") return "100";
    if (maxWidth === "large") return "200";
    if (maxWidth === false) return "";
  };
  if (!thumnailUrl) return <div></div>;

  return (
    <>
      {dialogMode && (
        <ThumnailDialog
          open={open}
          onClose={closeThumnailDialog}
          fullImageUrl={fullImageUrl}
        />
      )}
      <StyledThumnailImageWrapper onClick={openThumnailDialog}>
        <img
          src={thumnailUrl ?? ""}
          width={getWidth(maxWidth)}
          style={{ cursor: "pointer" }}
          alt="썸네일"
        />
        {dialogMode && zoomIcon ? <StyledZoomInIcon></StyledZoomInIcon> : null}
        <StyledLoadingWrapper>
          {isLoading ? <CircularProgress size={15} /> : null}
        </StyledLoadingWrapper>
      </StyledThumnailImageWrapper>
    </>
  );
}
