import { styled } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Control, Controller } from "react-hook-form";

import { Category } from "../../types/common";

function CategoryToggleGroup({
  categories,
  control,
  disabled,
  name,
  fullWidth
}: {
  categories: Category[];
  control: Control<any, any>;
  disabled?: boolean;
  name?: string;
  fullWidth?: boolean;
}) {
  return (
    <Controller
      render={({ field: props }) => (
        <StyledToggleGroupWrapper
          {...props}
          fullWidth={fullWidth}
          disabled={disabled}
          exclusive
          onChange={(e, value) => {
            props.onChange(value);
          }}
        >
          {categories.map((category) => (
            <ToggleButton key={category.value} value={category.value}>
              {category.label}
            </ToggleButton>
          ))}
        </StyledToggleGroupWrapper>
      )}
      name={name ?? "category"}
      control={control}
    />
  );
}

const StyledToggleGroupWrapper = styled(ToggleButtonGroup)(({ theme }) => ({
  "&.MuiToggleButtonGroup-root": {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  ".MuiToggleButton-root": {
    flexGrow: 1,
    border: "none",
    borderBottom: "3px solid transparent",

    "&.Mui-selected": {
      backgroundColor: "transparent",
      borderBottom: `3px solid ${theme.palette.primary.light}}`,
      borderRadius: "0px"
    }
  }
}));

export default CategoryToggleGroup;
