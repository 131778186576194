import { ReactNode, useMemo } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

type Props = {
  open: boolean;
  onClose?: () => void;
  children?: ReactNode;
  title?: string;
  fullWidth?: boolean;
  maxWidth?: "small" | "medium" | "large" | false;
};

export function BaseDialog({
  open,
  onClose,
  children,
  title,
  fullWidth = true,
  maxWidth = false
}: Props) {
  const maxWidthSize = useMemo(() => {
    if (maxWidth === "small") return "sm";
    if (maxWidth === "medium") return "md";
    if (maxWidth === "large") return "lg";
    return maxWidth;
  }, [maxWidth]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidthSize}
    >
      <DialogTitle>
        {title}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
