import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { HorizontalInputLabelProps } from "../../types";

export function HorizontalInputLabel({
  children,
  size
}: HorizontalInputLabelProps) {
  return (
    <StyledLabel component="label" size={size}>
      {children}
    </StyledLabel>
  );
}

type StyledProps = { size: HorizontalInputLabelProps["size"] };

const StyledLabel = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "size" && prop !== "fullWidth" && prop !== "width"
})<StyledProps>(({ theme, size }) => ({
  background: theme.palette.grey[200],
  textAlign: "end",
  padding:
    size === "small"
      ? `${theme.spacing(1)}`
      : `${theme.spacing(2)}  ${theme.spacing(1)}`,
  width: "25%",
  maxWidth: "170px",
  minWidth: "160px",
  wordBreak: "break-all",
  height: "fit-content",
  flexGrow: 1,
  flexShrink: 0,
  borderRadius: "3px",
  [theme.breakpoints.down("sm")]: {
    textAlign: "left"
  }
}));
