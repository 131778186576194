import { defaultWidth } from "@constants/drawer";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { drawerState } from "@store/recoil/drawerRecoil";
import { useRecoilState } from "recoil";

type Props = {
  handleDrawerToggle: () => void;
};
function AppHeader({ handleDrawerToggle }: Props) {
  const [drawerWidth, setDrawerWidth] = useRecoilState(drawerState);

  const handleTogglePcDrawer = () => {
    if (drawerWidth) setDrawerWidth(0);
    else setDrawerWidth(defaultWidth);
  };

  return (
    <StyledAppBar drawerWidth={drawerWidth}>
      <StyledToolbar>
        <StyledPcMenuButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleTogglePcDrawer}
        >
          <MenuIcon />
        </StyledPcMenuButton>
        <StyledMobileMenuButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </StyledMobileMenuButton>
      </StyledToolbar>
    </StyledAppBar>
  );
}

type StyledWidthProps = { drawerWidth: number };

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "drawerWidth"
})<StyledWidthProps>(({ theme, drawerWidth }) => ({
  position: "fixed",
  alignItems: "flex-start",
  backgroundColor: theme.palette.primary.main,
  marginLeft: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`
  }
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: "space-between",
  [theme.breakpoints.up("sm")]: {
    justifyContent: "end"
  }
}));

const StyledPcMenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    display: "none"
  }
}));

const StyledMobileMenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    display: "none"
  }
}));

export default AppHeader;
