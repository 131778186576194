import { useMemo } from "react";

import AutorenewIcon from "@mui/icons-material/Autorenew";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { UseFormResetField, UseFormSetValue } from "react-hook-form";

import { Subcategory } from "../../types/common";

function SubcategoryChip({
  subcategories,
  subcategoryWatch,
  setValue,
  disabled,
  name,
  resetField
}: {
  subcategories: Subcategory[];
  subcategoryWatch: any[];
  setValue: UseFormSetValue<any>;
  disabled: boolean;
  name?: string;
  resetField?: UseFormResetField<any>;
}) {
  const fieldName = useMemo(() => name ?? "subcategory", [name]);
  const handleDeleteChip = (val: string | number) => {
    const filteredDelete = subcategoryWatch.filter(
      (subValue: number | string) => subValue !== val
    );
    setValue(fieldName, filteredDelete, {
      shouldDirty: true,
      shouldValidate: true
    });
  };

  const handleResetSubcategory = () => {
    if (resetField) resetField(fieldName);
    else setValue(fieldName, [], { shouldDirty: true, shouldValidate: true });
  };

  const selected = useMemo(
    () => subcategories.filter((sub) => subcategoryWatch.includes(sub.value)),
    [subcategoryWatch, subcategories]
  );
  return (
    <Box
      component={Paper}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 3.5,
        mt: 2
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: 1,
          rowGap: 1
        }}
      >
        {selected.map((sub) => (
          <Chip
            disabled={disabled}
            key={sub.value}
            label={sub.label}
            color="primary"
            variant="outlined"
            sx={{ mb: 1 }}
            onDelete={() => handleDeleteChip(sub.value)}
          />
        ))}
      </Box>
      {selected.length > 0 && (
        <Box>
          <IconButton
            disabled={disabled}
            aria-label="delete"
            color="primary"
            onClick={handleResetSubcategory}
          >
            <AutorenewIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default SubcategoryChip;
