import { useCallback, useEffect } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { removeItemFromObject, removeItemsFromObject } from "shared-utils";

import { BaseCheckboxGroup } from "../../input/BaseCheckboxGroup";
import { BaseCheckboxFilterProps } from "../../types/filter.type";
import { FilterTyphograpy } from "../FilterTyphography";
import { FilterWrapper } from "../FilterWrapper";

type FormValues = {
  filterGroup: string[];
  checkAll: boolean;
};

function BaseCheckboxGroupFilter({
  name, // query 이름
  options,
  title,
  bg,
  elevation = 0,
  variant = "elevation"
}: BaseCheckboxFilterProps) {
  const router = useRouter();

  const { setValue, control, watch } = useForm<FormValues>({
    defaultValues: {
      filterGroup: [],
      checkAll: true
    }
  });

  const filterGroupWatch = watch("filterGroup");

  const setFirstFormValues = () => {
    if (router.isReady) {
      const queryKeys = Object.keys(router.query);

      const transformToStringArray = (value?: string | string[]) => {
        if (typeof value === "string") {
          return [value];
        }
        return value;
      };

      const transformedToArray = transformToStringArray(router.query[name]);

      const transformedFilterValues = transformedToArray;

      const hasFilterGroup = queryKeys.includes(name) ? true : false;

      if (hasFilterGroup && transformedFilterValues) {
        setValue("filterGroup", transformedFilterValues);
        setValue("checkAll", false);
      }
    }
  };

  useEffect(() => {
    setFirstFormValues();
  }, [router.isReady]);

  const setQueryByFormValues = useCallback(
    (value: string[]) => {
      const isValueExist = value.length > 0;

      if (isValueExist) {
        router.replace({
          pathname: router.basePath,
          query: {
            ...removeItemFromObject(router.query, "page"),
            [name]: value
          }
        });
      } else {
        const query = removeItemsFromObject(router.query, [name, "page"]);
        router.replace({
          pathname: router.basePath,
          query
        });
      }
    },
    [router.query]
  );

  useEffect(() => {
    setQueryByFormValues(filterGroupWatch);
  }, [filterGroupWatch]);

  return (
    <FilterWrapper elevation={elevation} variant={variant}>
      <>
        {title && <FilterTyphograpy title={title} />}
        <BaseCheckboxGroup
          checkAllMode={true}
          options={options}
          name="filterGroup"
          control={control}
          setValue={setValue}
          bg={bg}
        />
      </>
    </FilterWrapper>
  );
}

export default BaseCheckboxGroupFilter;
