import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { FieldValues, Path } from "react-hook-form";

import { BaseCrontabType } from "../../types";
import { Crontab, FormError } from "../../types/common";
import { BaseInput } from "../BaseInput";
import { BaseMultipleSelect } from "../BaseMultipleSelect";
import { FormInputWrapper } from "../FormInputWrapper";

export type FormValues = {
  area: string[];
  region: string | null;
  crontab: Crontab;
  argument: number | null | "";
  type: number;
  is_active: number;
};

export const OPTIONS_DAYS = [
  { value: 1, label: "월" },
  { value: 2, label: "화" },
  { value: 3, label: "수" },
  { value: 4, label: "목" },
  { value: 5, label: "금" },
  { value: 6, label: "토" },
  { value: 7, label: "일" }
];

function BaseCrontabGroup<TFieldValues extends FieldValues>({
  options = OPTIONS_DAYS,
  control,
  fullWidth,
  size,
  disabled,
  errors,
  setValue,
  layoutType,
  label,
  helperText,
  name = "crontab"
}: BaseCrontabType<TFieldValues>) {
  return (
    <FormInputWrapper
      layoutType={layoutType}
      fullWidth={fullWidth}
      label={label}
      size={size}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: fullWidth ? "100%" : "auto"
        }}
      >
        <CrontabHelperText>{helperText}</CrontabHelperText>
        <BaseMultipleSelect
          options={options}
          name={`${name}.days`}
          control={control}
          label="요일"
          fullWidth={fullWidth}
          size={size}
          disabled={disabled}
          error={errors?.[name]?.["days"]}
          setValue={setValue}
          layoutType={"default"}
        />
        <BaseInput
          size={size}
          label="시"
          name={`${name}.hour`}
          control={control}
          fullWidth={fullWidth}
          type="number"
          error={errors?.[name]?.["hour"]}
        />
        <BaseInput
          size={size}
          label="분"
          name={`${name}.min`}
          control={control}
          fullWidth={fullWidth}
          type="number"
          error={errors?.[name]?.["min"]}
        />
      </Box>
    </FormInputWrapper>
  );
}

const CrontabHelperText = styled(Box)(() => ({
  fontSize: "0.75rem",
  lineHeight: "22px",
  color: "rgba(0, 0, 0, 0.6)"
}));

export default BaseCrontabGroup;
