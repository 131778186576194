import { ReactElement, useEffect, useMemo } from "react";

import SidebarItem from "@layouts/Sidebar/SidebarItem";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import { styled } from "@mui/material/styles";
import { accountState } from "@store/recoil/accountRecoil";
import { useRecoilValue } from "recoil";

import LogoutButton from "./LogoutButton";
import { menus, staffMenus } from "./menus";
import { MenuItem } from "./menus.interface";
import SidebarProfile from "./SidebarProfile";

function SidebarNav() {
  const { role } = useRecoilValue(accountState);

  const sideMenu = useMemo(() => {
    return role === "가맹점주" ? menus : staffMenus;
  }, [role]);

  return (
    <>
      <Box p={2}>
        {sideMenu.map((menuGroup, index) => (
          <List
            key={index}
            aria-labelledby="nested-list-subheader"
            component="ul"
            subheader={
              menuGroup.subheader && (
                <StyledListSubheader id="nested-list-subheader">
                  {menuGroup.subheader}
                </StyledListSubheader>
              )
            }
          >
            {renderSidebarItems({
              items: menuGroup.items
            })}
          </List>
        ))}
        <Divider
          sx={{
            paddingY: 1
          }}
        />
        <Box sx={{ paddingTop: 1 }}>
          <SidebarProfile />
          <LogoutButton />
        </Box>
      </Box>
    </>
  );
}

function renderSidebarItems({
  items,
  depth = 0
}: {
  items?: MenuItem[];
  depth?: number;
}) {
  return (
    <List>
      {items?.reduce((acc: ReactElement[], item) => {
        return reduceItemsRoutes({ depth, item, acc });
      }, [])}
    </List>
  );
}

function reduceItemsRoutes({
  acc,
  item,
  depth
}: {
  depth: number;
  item: MenuItem;
  acc: ReactElement[];
}) {
  const key = depth + item.title;

  if (item.items) {
    acc.push(
      <SidebarItem depth={depth} icon={item.icon} key={key} title={item.title}>
        {renderSidebarItems({
          depth: depth + 1,
          items: item.items
        })}
      </SidebarItem>
    );
  } else {
    acc.push(
      <SidebarItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}

const StyledListSubheader = styled(ListSubheader)(() => ({
  padding: 0
}));

export default SidebarNav;
