import DialogTitle from "@mui/material/DialogTitle";

import { BaseDialogActions } from "../BaseDialog/BaseDialogActions";
import {
  StyledDialogContent,
  StyledButton,
  StyledDialogContainer
} from "../styles";

export function BaseConfirmDialog({
  open,
  onCancel,
  onConfirm,
  title,
  content
}) {
  return (
    <StyledDialogContainer open={open} onClose={onCancel} maxWidth="sm">
      <DialogTitle sx={{ fontSize: "16px", fontWeight: 700 }}>
        {title}
      </DialogTitle>
      <StyledDialogContent
        sx={{
          width: "320px",
          maxWidth: "100%",
          fontSize: "14px",
          whiteSpace: "pre-line",
          lineHeight: "1.4",
          textAlign: "left"
        }}
        dangerouslySetInnerHTML={{ __html: content }}
      ></StyledDialogContent>
      <BaseDialogActions>
        <StyledButton variant="outlined" onClick={onCancel}>
          취소
        </StyledButton>
        <StyledButton variant="contained" onClick={onConfirm}>
          확인
        </StyledButton>
      </BaseDialogActions>
    </StyledDialogContainer>
  );
}
