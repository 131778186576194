import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Controller, FieldValues, Path } from "react-hook-form";

import { Option } from "../../types/common";
import { BaseRadioGroupProps } from "../../types/form";
import { FormInputWrapper } from "../FormInputWrapper/FormInputWrapper";

export function BaseRadioGroup<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  width,
  size,
  layoutType = "default",
  disabled = false,
  helperText,
  error,
  options,
  fullWidth = true
}: BaseRadioGroupProps<TFieldValues>) {
  return (
    <FormInputWrapper
      layoutType={layoutType}
      width={width}
      fullWidth={fullWidth}
      label={label}
      size={size}
    >
      <FormControl
        component="fieldset"
        error={error ? true : false}
        fullWidth={fullWidth}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box>
            <Box>
              {layoutType === "default" && <FormLabel>{label}</FormLabel>}
              <Controller
                control={control}
                name={name as Path<TFieldValues>}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    onChange={(event, value) => field.onChange(value)}
                    value={field.value}
                  >
                    {options.map((option: Option<any>) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        disabled={disabled}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Box>
            {error || helperText ? (
              <FormHelperText>{error?.message || helperText}</FormHelperText>
            ) : null}
          </Box>
        </Box>
      </FormControl>
    </FormInputWrapper>
  );
}
