import { getFetcher, patchFetcher } from "@network/fetchers";
import {
  GetAllBikesResponse,
  GetBikeDetailResponse,
  PatchBikeDetailRequestParams
} from "types/bike.types";
import { GetBikeRawDataRequestParams } from "types/bikeRaw.types";
import { EmptyResponse } from "types/common.types";
import {
  GetTableBikesRequestParams,
  GetTableBikesResponse
} from "types/tableBike.types";

function getAll() {
  return getFetcher<GetAllBikesResponse>("/v1/bike/map");
}

function getTable(params?: GetTableBikesRequestParams) {
  return getFetcher<GetTableBikesResponse>("/bike/table", params);
}

async function getRawData(id: number, params: GetBikeRawDataRequestParams) {
  // TODO : 추후 리스폰스 타입 적용 필요
  const data = await getFetcher(`bike/${id}/rawdata`, params);

  return data.map((item: any) => {
    return {
      ...item,
      point: item.position ? item.position.reverse() : item.point,
      ...item.condition,
      deviceId: item.deviceId || item.device_id,
      device_battery: item.device_battery || item.device_percent
    };
  });
}

function getBikeDetail(id: number) {
  return getFetcher<GetBikeDetailResponse>(`bike/map/${id}`);
}

function patchBikeDetail(id: number, params: PatchBikeDetailRequestParams) {
  return patchFetcher<EmptyResponse>(`bike/${id}`, params);
}

const bikeApi = () => {
  return {
    getAll,
    getTable,
    getRawData,
    getBikeDetail,
    patchBikeDetail
  };
};

export default bikeApi;
