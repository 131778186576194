import { styled, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Controller, FieldValues, Path } from "react-hook-form";

import { BaseCheckboxGroupProps } from "../../types";
import { ColorSet } from "../../types/common";
import { FormInputWrapper } from "../FormInputWrapper/FormInputWrapper";

function BaseCheckboxGroup<TFieldValues extends FieldValues>({
  control,
  layoutType = "default",
  size = "medium",
  label,
  options,
  name,
  fullWidth = true,
  width,
  bg = "default",
  filteredList,
  checkAllMode = false,
  setValue,
  disabled,
  error
}: BaseCheckboxGroupProps<TFieldValues>) {
  const onChangeItem = (prev: string[], value: string) => {
    const isDuplicate = prev.includes(value);
    const filterDuplicate = (arr: string[], val: string) => {
      return arr.filter((item) => item !== val);
    };

    const newCheckboxGroup = isDuplicate
      ? filterDuplicate(prev, value)
      : [...prev, value];

    const hasCheckboxGroupValue = newCheckboxGroup.length > 0;

    // checkAll은 필터가 있으면 false, 없으면 true입니다.
    setValue && setValue("checkAll", !hasCheckboxGroupValue);

    return newCheckboxGroup;
  };

  const onChangeAll = () => {
    // 전체 선택시, 모든 필터 해제
    setValue && setValue(name, [] as TFieldValues[Path<TFieldValues>]);
    // checkAll 체크박스에선 전체 선택 해제는 불가능하고, onChangeItem에서 필터 선택 시 해제됩니다. 그래서 반환 값은 항상 참입니다.
    return true;
  };

  return (
    <FormInputWrapper
      layoutType={layoutType}
      fullWidth={fullWidth}
      width={width}
      label={label}
      size={size}
      error={error}
    >
      <StyledCheckboxGroupWrapper bg={bg} layoutType={layoutType}>
        {checkAllMode && (
          <FormControlLabel
            control={
              <Controller
                name={"checkAll" as Path<TFieldValues>}
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    disabled={disabled}
                    checked={props.value}
                    onChange={() => props.onChange(onChangeAll())}
                    size={size}
                  />
                )}
              />
            }
            label={"전체"}
          />
        )}
        {options.map((option) => (
          <StyledCheckboxItem
            key={option.value}
            show={!filteredList || filteredList.includes(option.value)}
            control={
              <Controller
                name={name as Path<TFieldValues>}
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    disabled={disabled}
                    {...props}
                    checked={props.value?.includes(option.value)}
                    onChange={() =>
                      props.onChange(onChangeItem(props.value, option.value))
                    }
                  />
                )}
              />
            }
            label={option.label}
          />
        ))}
      </StyledCheckboxGroupWrapper>
    </FormInputWrapper>
  );
}

type StyledWrapperProps = {
  bg: ColorSet;
  layoutType: BaseCheckboxGroupProps<any>["layoutType"];
};

type StyledCheckboxProps = {
  show: boolean;
};

const StyledCheckboxGroupWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "bg" && prop !== "layoutType"
})<StyledWrapperProps>(({ theme, bg, layoutType }) => ({
  display: layoutType === "vertical" ? "flex" : "grid",
  flexDirection: layoutType === "vertical" ? "column" : "row",
  width: "100%",
  gridTemplateColumns: "repeat(2, 1fr)",
  padding: layoutType === "vertical" ? theme.spacing(2.5) : 0,
  gap: theme.spacing(1),
  backgroundColor: setBgColor(theme, bg ?? "default"),
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "repeat(5, 1fr)"
  },
  ".MuiFormControlLabel-root": {
    display: "flex"
  }
}));

const setBgColor = (theme: Theme, color: string) => {
  switch (color) {
    case "gray":
      return theme.palette.grey[200];
    case "default":
      return "transparent";
    case "white":
      return "white";
    default:
      return color;
  }
};

const StyledCheckboxItem = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== "show"
})<StyledCheckboxProps>(({ show }) => ({
  display: show ? "block" : "none !important"
}));
export default BaseCheckboxGroup;
