import React from "react";

import Box from "@mui/material/Box";

type InquiryTableDataProps = {
  inquiry: {
    question: string;
    answer: string;
  } | null;
};
function InquiryTableData({ inquiry }: InquiryTableDataProps) {
  return (
    <>
      {inquiry?.question ? (
        <Box sx={{ fontSize: "16px" }}>
          <Box>
            <strong>Q.</strong> {inquiry.question}
          </Box>
          <Box>
            <strong>A.</strong> {inquiry.answer ? inquiry.answer : "미응답"}
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default InquiryTableData;
