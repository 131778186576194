import { useMemo } from "react";

import styled from "@emotion/styled";
import DoubleArrow from "@mui/icons-material/DoubleArrow";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import { PaginationMeta, TablePaginationControls } from "./types";

export function TablePaginationStepper({
  currentPage,
  pageLength,
  onPageChanged
}: PaginationMeta & TablePaginationControls) {
  const showPrevDots = useMemo(() => {
    if (currentPage > 3) {
      return true;
    } else {
      return false;
    }
  }, [currentPage]);
  const showNextDots = useMemo(() => {
    if (currentPage < pageLength - 2) {
      return true;
    } else {
      return false;
    }
  }, [currentPage, pageLength]);
  const onClickPageNumber = (page: number) => {
    if (onPageChanged) {
      if (page >= 1 && page <= pageLength) {
        onPageChanged(page);
      }
    }
  };
  return (
    <div>
      {/* << < [1] 2 ... 500 > */}
      {/* << < 1 [2] 3 ... 500 > */}
      {/* << < ... 2 [3] 4 ... 500 > */}
      {/* << < ...497 [498] 499 500 > */}
      {/* << < ...498 [499] 500 > */}
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        {currentPage > 2 && (
          <PageNumberButton onClick={() => onClickPageNumber(1)}>
            <BackwardDoubleArrow fontSize="small" />
          </PageNumberButton>
        )}
        {currentPage > 1 && (
          <PageNumberButton onClick={() => onClickPageNumber(currentPage - 1)}>
            <KeyboardArrowLeft fontSize="small" />
          </PageNumberButton>
        )}

        {showPrevDots && <PageNumberButton>...</PageNumberButton>}
        {currentPage > 2 && (
          <PageNumberButton onClick={() => onClickPageNumber(currentPage - 2)}>
            {currentPage - 2}
          </PageNumberButton>
        )}
        {currentPage > 1 && (
          <PageNumberButton onClick={() => onClickPageNumber(currentPage - 1)}>
            {currentPage - 1}
          </PageNumberButton>
        )}
        <PageNumberButton variant="contained">{currentPage}</PageNumberButton>
        {currentPage < pageLength && (
          <PageNumberButton onClick={() => onClickPageNumber(currentPage + 1)}>
            {currentPage + 1}
          </PageNumberButton>
        )}

        {currentPage < pageLength - 1 && (
          <PageNumberButton onClick={() => onClickPageNumber(currentPage + 2)}>
            {currentPage + 2}
          </PageNumberButton>
        )}
        {showNextDots && <PageNumberButton>...</PageNumberButton>}
        {currentPage < pageLength - 2 && (
          <PageNumberButton onClick={() => onClickPageNumber(pageLength)}>
            {pageLength}
          </PageNumberButton>
        )}
        {currentPage < pageLength && (
          <PageNumberButton onClick={() => onClickPageNumber(currentPage + 1)}>
            <KeyboardArrowRight fontSize="small" />
          </PageNumberButton>
        )}
      </ButtonGroup>
    </div>
  );
}

const DenseButton = styled(Button)`
  height: 22px;
`;

const PageNumberButton = styled(DenseButton)`
  margin-left: 0;
  margin-right: 0;
  width: 36px;
  height: 28px;
  min-width: 20px !important;
  font-size: 10px;
`;

const BackwardDoubleArrow = styled(DoubleArrow)`
  transform: rotateY(180deg);
`;
