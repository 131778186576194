import { ReactNode } from "react";

import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";

import { FormInputWrapper } from "../FormInputWrapper/FormInputWrapper";

type Props = {
  label: string;
  fullWidth?: boolean;
  width?: number;
  size?: "small" | "medium";
  layoutType?: "horizontal" | "default";
  helperText?: string;
  children: ReactNode;
};

export function BaseIndentGroup({
  label,
  fullWidth,
  width = 200,
  size,
  layoutType = "default",
  helperText,
  children
}: Props) {
  return (
    <Box>
      <FormInputWrapper
        layoutType={layoutType}
        fullWidth={fullWidth}
        width={width}
        label={label}
        size={size}
      >
        {helperText && (
          <HelperText sx={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "0.8rem" }}>
            {helperText}
          </HelperText>
        )}
      </FormInputWrapper>
      <StyleStack gap={1}>{children}</StyleStack>
    </Box>
  );
}

const StyleStack = styled(Stack)(({ theme }) => ({
  padding: "8px 16px 16px",
  margin: "0 32px 8px 32px"
}));

const HelperText = styled(Box)(({ theme }) => ({
  color: "rgba(0, 0, 0, 0.6)",
  fontSize: "0.8rem"
}));
