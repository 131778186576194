import Box from "@mui/material/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FieldError, FieldValues } from "react-hook-form";

import {
  defaultDurationInpuDisabled,
  defaultDurationInputLabel,
  defaultDurationInputName,
  defaultDurationLayoutType
} from "../../constant/time.constant";
import { BaseTimeRangeInputProps } from "../../types";
import { BaseTimeInput } from "../BaseTimeInput";
import { FormInputWrapper } from "../FormInputWrapper/FormInputWrapper";

// DEPRECATED
function BaseTimeRangeInput<TFieldValues extends FieldValues>({
  control,
  size,
  layoutType = defaultDurationLayoutType,
  label = defaultDurationInputLabel,
  name = defaultDurationInputName,
  disabled = defaultDurationInpuDisabled,
  helperText,
  errors
}: BaseTimeRangeInputProps<TFieldValues>) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
        <FormInputWrapper
          layoutType={layoutType}
          label={label.group}
          size={size}
          fullWidth
        >
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <BaseTimeInput
              size={size}
              name={name.start_at}
              control={control}
              label={label?.start_at}
              error={errors?.[name.start_at] as FieldError}
              helperText={helperText?.start_at}
              disabled={disabled.start_at}
            />
            <BaseTimeInput
              size={size}
              name={name.end_at}
              control={control}
              label={label?.end_at}
              error={errors?.[name.end_at] as FieldError}
              helperText={helperText?.end_at}
              disabled={disabled.end_at}
            />
          </Box>
        </FormInputWrapper>
      </LocalizationProvider>
    </>
  );
}

export default BaseTimeRangeInput;
