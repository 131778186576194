import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export function MapLoading() {
  return (
    <StyledMapLoading>
      <CircularProgress />
    </StyledMapLoading>
  );
}

const StyledMapLoading = styled(Box)`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
