import { cloneElement } from "react";

import { grey } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { GridValidRowModel } from "@mui/x-data-grid";
import { FieldValues, Path } from "react-hook-form";

import { BaseAlertDialog, BaseConfirmDialog, BaseDialog } from "../dialog";
import { FilterGenerator } from "../filter-generator";
import { FormGenerator } from "../form-generator";
import { BaseTable } from "../table/BaseTable";
import { BaseTableColumns } from "../table/BaseTable/types";
import { TablePageGeneratorProps } from "../types/tableGenerator.type";

import { CreateButton } from "./CreateButton";
import usePagination from "./usePagination";
import useSpecificDialog from "../specificDialogProvider/useSpecificDialog";
import { withContextProvider } from "../withContextProvider";
import { SpecificDialogProvider } from "../specificDialogProvider/SpecificDialogProvider";
import { SystemDialogProvider } from "../systemDialogProvider/SystemDialogProvider";
import { useSystemDialog } from "../form-generator/useSystemDialog";

/**
 * @description 테이블 페이지를 만들어주는 컴포넌트입니다. 테이블, 필터, 페이징, 생성하기 버튼, 수정하기 버튼, 삭제하기 버튼, 상세보기 버튼, 다이얼로그를 포함합니다.
 * @param filterInputInfos 필터 인풋들의 정보 - FilterGenerator 컴포넌트에 전달됩니다.
 * @param specificComponent 상세보기 클릭시 모달로 보여질 specifics 컴포넌트, selected된 row의 정보를 props로 받습니다.
 * @param specificComponentProps specificComponent에 전달될 props
 * @param formGenertorProps 생성하기, 수정하기 버튼 클릭시 모달로 보여질 FormGenerator에 전달할 props
 * @param columns 테이블의 컬럼 정보
 * @param rows 테이블의 row 정보
 * @param count 전체 데이터 갯수
 * @param canCreate 생성하기 버튼을 보여줄지 여부
 * @param title 상단에 표시될 제목
 * @param loading 테이블 로딩 여부
 */
export const TablePageGeneratorInner = <T extends GridValidRowModel>({
  filterInputInfos,
  SpecificComponent,
  specificComponentProps,
  formGenertorProps,
  count,
  rows,
  columns,
  loading,
  title,
  canCreate = false
}: TablePageGeneratorProps<T>) => {
  const { paginationMeta } = usePagination(count);
  const {
    setIsDialogOpen,
    handleCloseWithConfirmChanges,
    setDialogType,
    handleDetailClick,
    dialogType,
    selected,
    isDialogOpen,
    setShouldConfirmChanges
  } = useSpecificDialog();

  const {
    open,
    title: dialogTitle,
    content,
    onCancel: onDialogCancel,
    onConfirm: onDialogConfirm
  } = useSystemDialog();

  const columnData: BaseTableColumns<T> =
    columns ?? Object.keys(rows?.[0] ?? []).map((key) => ({ field: key }));

  const columnWithPinnable =
    SpecificComponent || formGenertorProps
      ? [
          ...columnData,
          {
            pinnable: true,
            field: "detail",
            headerName: "상세보기",
            onPinnedClick: handleDetailClick
          }
        ]
      : columnData;

  const getDisabled = (inputInfo) => {
    return {
      disabled: inputInfo.disabled,
      disabledStartDate:
        (inputInfo.disabledStartDate ||
          (inputInfo.disableStartDateOnEdit &&
            selected &&
            inputInfo.disableStartDateOnEdit(selected))) ??
        undefined,
      disabledEndDate:
        (inputInfo.disabledEndDate ||
          (inputInfo.disableEndDateOnEdit &&
            selected &&
            inputInfo.disableEndDateOnEdit(selected))) ??
        undefined
    };
  };

  const formGenertorPropsWithDefaults = formGenertorProps
    ? {
        ...formGenertorProps,
        inputInfos: formGenertorProps.inputInfos.map((inputInfo) => ({
          ...inputInfo,
          name: inputInfo.name as Path<FieldValues>,
          size: inputInfo.size ?? "small",
          layoutType: inputInfo.layoutType ?? "horizontal",
          defaultValue:
            selected && dialogType === "edit"
              ? selected[inputInfo.name]
              : inputInfo.defaultValue,
          ...getDisabled(inputInfo)
        }))
      }
    : undefined;

  return (
    <Stack spacing={2} p={2} flexGrow={1} sx={{ background: grey[50] }}>
      <Typography variant="h5" sx={{ fontWeight: 700 }}>
        {title}
      </Typography>

      {canCreate ? (
        <CreateButton
          onClick={() => {
            setDialogType("create");
            setIsDialogOpen(true);
          }}
        />
      ) : null}

      {filterInputInfos ? (
        <FilterGenerator filterInputInfos={filterInputInfos} />
      ) : null}

      <BaseTable
        rows={rows}
        columns={columnWithPinnable}
        paginationMeta={paginationMeta}
        isLoading={loading}
      ></BaseTable>

      <BaseDialog
        open={isDialogOpen}
        onClose={handleCloseWithConfirmChanges}
        maxWidth={"medium"}
        title={title}
      >
        {SpecificComponent
          ? cloneElement(<SpecificComponent />, {
              selected,
              ...specificComponentProps
            })
          : null}

        {formGenertorPropsWithDefaults && (
          <FormGenerator
            {...formGenertorPropsWithDefaults}
            setShouldConfirmChanges={setShouldConfirmChanges}
            onCancel={handleCloseWithConfirmChanges}
            closeDialog={() => setIsDialogOpen(false)}
            mode={dialogType}
          />
        )}
      </BaseDialog>
      <BaseConfirmDialog
        open={open === "confirm"}
        title={dialogTitle}
        content={content}
        onCancel={onDialogCancel}
        onConfirm={onDialogConfirm}
      ></BaseConfirmDialog>
    </Stack>
  );
};

/**
 * @description TablePageGeneratorInner에 Provider를 추가한 컴포넌트입니다.
 * @see TablePageGeneratorInner
 * @see SpecificDialogProvider
 * @see SystemDialogProvider
 */
export const TablePageGenerator = withContextProvider(
  [SpecificDialogProvider, SystemDialogProvider],
  TablePageGeneratorInner
);
