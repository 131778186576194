import { ChangeEvent, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { FieldValues, Path, useController } from "react-hook-form";

import { BaseCsvInputProps } from "../../types";
import type { FormError } from "../../types/common";
import { BaseAutocomplete } from "../BaseAutocomplete";
import { BaseFileInput } from "../BaseFileInput";

import useCsv from "./useCsv";
1;
export default function BaseCsvInput<T extends FieldValues>({
  control,
  name,
  layoutType,
  fullWidth,
  width,
  size,
  error,
  label,
  helperText,
  disabled,
  setValue,
  placeholder,
  exampleCsvList,
  exampleCsvFieldName
}: BaseCsvInputProps<T>) {
  const [csvInputType, setCsvInputType] = useState<"file" | "text">("text");

  const [fileName, setFileName] = useState<string>();
  const { convertCsvToArray, downloadCsvExample } = useCsv({
    exampleCsvList,
    exampleCsvFieldName
  });

  const handleChangeByCsv = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setFileName(file.name);

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const csvData = reader.result as string;
      convertCsvToArray(csvData, (result) => {
        setValue &&
          setValue(name, result, {
            shouldDirty: true,
            shouldValidate: true
          });
      });
    };
  };

  const toggleCsvInputType = () => {
    const confirmed = confirm("기존에 입력되어 있는 데이터는 초기화됩니다.");

    if (!confirmed) return;

    setValue && setValue(name, []);

    if (csvInputType === "file") {
      setCsvInputType("text");
      setFileName(undefined);
    } else {
      setCsvInputType("file");
    }
  };

  const { field } = useController({
    name: name as Path<T>,
    control
  });

  useEffect(() => {
    if (!field.value.length) setFileName(undefined);
  }, [field.value]);

  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems="start">
        {csvInputType === "file" ? (
          <BaseFileInput
            fullWidth={fullWidth}
            layoutType={layoutType}
            size={size}
            control={control}
            name={name}
            accept=".csv"
            fileName={fileName}
            onChange={handleChangeByCsv}
            helperText={helperText}
            disabled={disabled}
            error={error}
            label={label}
            width={width}
            placeholder={placeholder}
          />
        ) : (
          <BaseAutocomplete
            fullWidth={fullWidth}
            size={size}
            freeSolo
            control={control}
            layoutType={layoutType}
            name={name}
            options={[]}
            multiple
            disabled={disabled}
            renderTags={(_, getTagProps) => {
              return field.value.map((value: string, index) => {
                return (
                  <Chip
                    variant="outlined"
                    label={value}
                    {...getTagProps({ index })}
                  />
                );
              });
            }}
            label={label}
            width={width}
            helperText={helperText}
            error={error as FormError}
            placeholder={placeholder}
            componentType="chip"
          />
        )}
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={0.5}
      >
        <Button
          onClick={toggleCsvInputType}
          disabled={disabled}
          size="small"
          sx={{
            marginLeft: "50%",
            transform: "translateX(-50%)"
          }}
        >
          {csvInputType === "file" ? "직접 입력하여 추가" : "CSV 파일로 추가"}
        </Button>
        {csvInputType === "file" ? (
          <Link
            component="button"
            variant="body2"
            color="grey.600"
            onClick={downloadCsvExample}
          >
            CSV Example
          </Link>
        ) : null}
      </Stack>
    </Box>
  );
}
