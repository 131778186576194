import DialogTitle from "@mui/material/DialogTitle";

import { BaseDialogActions } from "../BaseDialog/BaseDialogActions";
import {
  StyledButton,
  StyledDialogContainer,
  StyledDialogContent
} from "../styles";
export function BaseAlertDialog({ open, onConfirm, title, content }) {
  return (
    <StyledDialogContainer open={open} maxWidth="sm">
      <DialogTitle sx={{ fontSize: "16px", fontWeight: 700 }}>
        {title}
      </DialogTitle>
      <StyledDialogContent
        dangerouslySetInnerHTML={{ __html: content }}
      ></StyledDialogContent>
      <BaseDialogActions>
        <StyledButton variant="contained" onClick={onConfirm}>
          확인
        </StyledButton>
      </BaseDialogActions>
    </StyledDialogContainer>
  );
}
