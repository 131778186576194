import Box from "@mui/material/Box";

import { TablePaginationStepper } from "./TablePaginationStepper";
import { TablePaginationTransporter } from "./TablePaginationTransporter";
import { PaginationMeta, TablePaginationControls } from "./types";

export function TablePagination(
  paginationProps: PaginationMeta & TablePaginationControls
) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        height: "36px",
        py: 3,
        px: 1
      }}
    >
      <TablePaginationStepper {...paginationProps} />
      <TablePaginationTransporter {...paginationProps} />
    </Box>
  );
}
