import { Menu } from "@layouts/Sidebar/menus.interface";
import { EditOutlined } from "@mui/icons-material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ListAltIcon from "@mui/icons-material/ListAlt";

export const menus: Menu[] = [
  {
    subheader: "대시보드",
    icon: <DirectionsBikeIcon />,
    items: [
      {
        title: "대시보드",
        icon: <FormatListNumberedIcon />,
        href: "/"
      }
    ]
  },
  {
    subheader: "이용관리",
    icon: <CopyAllIcon />,
    items: [
      {
        title: "라이딩 / 결제 내역",
        icon: <DirectionsBikeIcon />,
        href: "/riding/list"
      },
      {
        title: "고객문의",
        icon: <EditOutlined />,
        href: "/inquiry/list"
      }
    ]
  },
  {
    subheader: "현장관리",
    icon: <CopyAllIcon />,
    items: [
      {
        title: "관리내역",
        icon: <ErrorOutlineIcon />,
        href: "/maintenance/list"
      },
      {
        title: "기기목록",
        icon: <ListAltIcon />,
        href: "/bike/list"
      }
    ]
  }
];

export const staffMenus: Menu[] = [
  {
    subheader: "대시보드",
    icon: <DirectionsBikeIcon />,
    items: [
      {
        title: "대시보드",
        icon: <FormatListNumberedIcon />,
        href: "/"
      }
    ]
  }
];
