import { useEffect } from "react";

import { FieldValues, Path, useController } from "react-hook-form";

import useImageFile from "../../hooks/useImageFile";
import { BaseImageInputProps } from "../../types";
import { BaseFileInput } from "../BaseFileInput";

export function BaseImageInput<TFieldValues extends FieldValues>({
  name,
  label,
  fullWidth = true,
  width = 400,
  control,
  size,
  error,
  layoutType = "default",
  setValue,
  uploadImage,
  isLoading,
  imageDirectory = "etc",
  helperText,
  disabled
}: BaseImageInputProps<TFieldValues>) {
  const { field } = useController({
    name: name as Path<TFieldValues>,
    control
  });

  const { imageBase64, imageName, imageType, handleImageFile } = useImageFile();

  const onFileUpload = (e) => {
    e && handleImageFile(e);
  };

  useEffect(() => {
    if (!imageBase64 || !imageName) return;
    uploadImage(
      {
        mime: imageType,
        data: imageBase64,
        path: imageDirectory + "/" + imageName,
        overwrite: false,
        invalidation: false
      },
      {
        onSuccess: (data) => {
          setValue &&
            setValue(name, data.url as TFieldValues[Path<TFieldValues>], {
              shouldDirty: true,
              shouldValidate: true
            });
        }
      }
    );
  }, [imageBase64, imageName, imageType]);

  return (
    <BaseFileInput
      layoutType={layoutType}
      fullWidth={fullWidth}
      width={width}
      control={control}
      size={size}
      error={error}
      label={label}
      helperText={helperText}
      disabled={disabled}
      name={name}
      onChange={onFileUpload}
      accept="image/*"
      fileName={field.value}
      isLoading={isLoading}
    />
  );
}
