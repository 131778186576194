import { Dispatch, createContext, useContext, useState } from "react";

type SystemDialogType = "confirm" | "prompt" | "alert" | null;
type BaseSystemDialog = {
  title: string;
  content?: string;
  open: SystemDialogType;
  onCancel: null | ((value?: any) => void);
  onConfirm: null | ((value?: any) => void);
};

const SystemDialogContext = createContext<
  | [BaseSystemDialog, Dispatch<React.SetStateAction<BaseSystemDialog>>]
  | undefined
>(undefined);

export const SystemDialogProvider = ({ children }) => {
  const [dialogState, setDialogState] = useState<BaseSystemDialog>({
    open: null,
    title: "",
    content: "",
    onConfirm: () => {},
    onCancel: () => {}
  });

  return (
    <SystemDialogContext.Provider value={[dialogState, setDialogState]}>
      {children}
    </SystemDialogContext.Provider>
  );
};

export const useSystemDialogContext = () => {
  const context = useContext(SystemDialogContext);

  if (!context) {
    throw new Error(
      "useSystemDialog must be used within a SystemDialogProvider"
    );
  }
  return context;
};
