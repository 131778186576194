import React, { useState } from "react";

import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { PaginationMeta, TablePaginationControls } from "./types";

export function TablePaginationTransporter({
  pageLength,
  onPageChanged
}: PaginationMeta & TablePaginationControls) {
  const [pageNumber, setPageNumber] = useState<string>("");
  const onKeydown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (onPageChanged) {
      if (event.code == "Enter") {
        onPageChanged(Number(pageNumber));
      }
    }
  };
  const onChangePageNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageNumber(e.target.value);
  };
  return (
    <StyledContainerBox>
      <Typography variant="caption"> 페이지 이동&nbsp; </Typography>{" "}
      <OutlinedInput
        onKeyDown={onKeydown}
        onChange={onChangePageNumber}
        disableUnderline
      />{" "}
      <Typography variant="caption">/ {pageLength}</Typography>
    </StyledContainerBox>
  );
}

const StyledContainerBox = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block"
  }
}));

const OutlinedInput = styled(Input)`
  border: 1px solid #0390fc;
  border-radius: 5px;
  height: 28px;
  padding: 7px;
  font-size: 12px;
  width: 48px;
  input {
    text-align: right;
  }
`;
