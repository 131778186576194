import SidebarNav from "@layouts/Sidebar/SidebarNav";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { drawerState } from "@store/recoil/drawerRecoil";
import NextLink from "next/link";
import { useRecoilValue } from "recoil";

interface Props {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

function Sidebar({ mobileOpen, handleDrawerToggle }: Props) {
  const drawerWidth = useRecoilValue(drawerState);

  return (
    <>
      <StyledNavBox
        component="nav"
        aria-label="mailbox folders"
        drawerWidth={drawerWidth}
      >
        <StyledMobileDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          drawerWidth={drawerWidth}
        >
          <StyledLogoBox drawerWidth={drawerWidth}>
            <NextLink href={"/"}>
              <img src="/images/logo.png" width="120" alt="로고" />
            </NextLink>
          </StyledLogoBox>
          <SidebarNav />
        </StyledMobileDrawer>

        <StyledPcDrawer variant="permanent" open drawerWidth={drawerWidth}>
          <StyledLogoBox drawerWidth={drawerWidth}>
            <NextLink href={"/"}>
              <img src="/images/logo.png" width="120" alt="로고" />
            </NextLink>
          </StyledLogoBox>
          <SidebarNav />
        </StyledPcDrawer>
      </StyledNavBox>
    </>
  );
}

type StyledWidthProps = { drawerWidth: number };

const StyledNavBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "drawerWidth"
})<StyledWidthProps>(({ theme, drawerWidth }) => ({
  [theme.breakpoints.up("sm")]: {
    width: drawerWidth,
    flexShrink: 0
  }
}));

const StyledMobileDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "drawerWidth"
})<StyledWidthProps>(({ theme, drawerWidth }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none"
  },
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: drawerWidth
  }
}));

const StyledPcDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "drawerWidth"
})<StyledWidthProps>(({ theme, drawerWidth }) => ({
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block"
  },
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: drawerWidth,
    border: 0
  }
}));

const StyledLogoBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "drawerWidth"
})<StyledWidthProps>(({ theme, drawerWidth }) => ({
  backgroundColor: "black",
  padding: "12px",
  width: drawerWidth,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  [theme.breakpoints.up("sm")]: {
    padding: "16px"
  }
}));

export default Sidebar;
