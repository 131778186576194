import { ReactElement, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import NextLink from "next/link";
import { useRouter } from "next/router";

type Props = {
  depth: number;
  href?: string;
  icon: ReactElement;
  title: string;
  children?: ReactElement;
};

const SidebarItem = ({ depth, href, icon, title, children }: Props) => {
  const router = useRouter();
  const [open, setOpen] = useState(true);

  const handleMenuToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const paddingLeft = depth > 0 ? 32 + 8 * depth : 8;

  const style = { paddingLeft };
  const isActive = (path?: string) => router.route === path;

  if (children) {
    return (
      <StyledListItem disableGutters key={title}>
        <StyledButton onClick={handleMenuToggle} style={style}>
          <StyledIcon>{icon}</StyledIcon>
          <StyledTitle>{title}</StyledTitle>
          <StyledArrow>{open ? <ExpandLess /> : <ExpandMore />}</StyledArrow>
        </StyledButton>
        <Collapse in={open}>{children}</Collapse>
      </StyledListItem>
    );
  }

  return (
    <ListItem disableGutters key={title}>
      {href && (
        <NextLink href={href} passHref>
          <StyledButtonLink
            className={`${isActive(href) ? "active" : ""}`}
            style={style}
          >
            <StyledIcon>{icon}</StyledIcon>
            <StyledTitle>{title}</StyledTitle>
          </StyledButtonLink>
        </NextLink>
      )}
    </ListItem>
  );
};

const StyledListItem = styled(ListItem)(() => ({
  display: "block",
  paddingTop: "0",
  paddingBottom: "0"
}));

const StyledIcon = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginRight: "10px"
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  justifyContent: "flex-start",
  width: "100%",
  color: theme.palette.grey[800]
}));

const StyledArrow = styled(Box)(() => ({
  marginLeft: "auto"
}));

const StyledTitle = styled(Box)(() => ({
  marginRight: "auto"
}));

const StyledButtonLink = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  justifyContent: "flex-start",
  width: "100%",
  color: theme.palette.grey[800],
  "&.active": {
    backgroundColor: "#e3f8ff",
    color: theme.palette.grey[900],
    fontWeight: "bold"
  }
}));

export default SidebarItem;
