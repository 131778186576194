import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import { FilterWrapperProps } from "../types/filter.type";

export const FilterWrapper = ({
  elevation = 0,
  variant = "elevation",
  children,
  onSubmit
}: FilterWrapperProps) => (
  <Paper elevation={elevation} variant={variant}>
    <Box component={"form"} py={1.5} px={2} onSubmit={onSubmit}>
      {children}
    </Box>
  </Paper>
);
