import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller, FieldValues, Path } from "react-hook-form";

import { BaseSelectProps } from "../../types";
import { FormInputWrapper } from "../FormInputWrapper/FormInputWrapper";

export type SelectValues = { value: any; label: string }[];

export function BaseSelect<TFieldValues extends FieldValues>({
  options,
  name,
  control,
  label,
  fullWidth,
  width = 200,
  size,
  layoutType = "default",
  disabled = false,
  helperText,
  error
}: BaseSelectProps<TFieldValues>) {
  return (
    <FormInputWrapper
      layoutType={layoutType}
      fullWidth={fullWidth}
      width={width}
      label={label}
      size={size}
    >
      <>
        {layoutType === "default" && <InputLabel>{label}</InputLabel>}

        <Box sx={{ width: fullWidth ? "100%" : `${width}px` }}>
          <Controller
            control={control}
            name={name as Path<TFieldValues>}
            render={({ field }) => (
              <Select
                {...field}
                size={size}
                fullWidth
                disabled={disabled}
                error={error ? true : false}
                label={layoutType === "default" ? label : undefined}
              >
                {options.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {error?.message && (
            <FormHelperText error>{error?.message}</FormHelperText>
          )}

          {!error?.message && helperText && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
        </Box>
      </>
    </FormInputWrapper>
  );
}

export default BaseSelect;
