import Box from "@mui/material/Box";
import { blueGrey, grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

type StyledMoreWidthProps = {
  width: number;
};

export const OccasionOverlayContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  minHeight: "100px",
  fontSize: "18px"
}));

export const StyledBaseTableContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  position: "relative"
}));

export const StyledDataGrid = styled(DataGrid)(() => ({
  border: "none",
  "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within":
    {
      outline: "none"
    }
}));

export const StyledListDataGrid = styled(StyledDataGrid)(() => ({
  "& .MuiDataGrid-footerContainer": {
    border: "none"
  },
  "&.hasPinnable .MuiDataGrid-columnHeaders": {
    background: blueGrey[50]
  },
  ":only-child": {
    ".MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
      display: "none"
    }
  },
  ".MuiDataGrid-main > div": {
    maxHeight: "100%"
  },
  "&.MuiDataGrid-root--densityStandard .sticky": {
    position: "sticky",
    right: 0,
    top: 0,
    background: "white",
    padding: "0 !important"
  },
  "& .MuiDataGrid-row:hover .sticky": {
    background: grey["A100"]
  }
}));

export const PinnableHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== "width"
})<StyledMoreWidthProps>(({ width }) => ({
  width,
  position: "absolute",
  right: 0,
  top: 0,
  fontSize: 14,
  height: "56px",
  lineHeight: "58px",
  background: blueGrey[50],
  textAlign: "center",
  zIndex: 999,
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

export const dynamicHeightStyle = {
  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
    py: "15px"
  }
};

export const PinButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%"
}));
