import Box from "@mui/material/Box";
import { Control } from "react-hook-form";

import { Subcategory } from "../../types/common";
import { BaseCheckboxGroup } from "../BaseCheckboxGroup";

function SubcategoryCheckBox({
  control,
  subcategories,
  filteredList,
  placeholder,
  disabled,
  name
}: {
  control: Control<any, any>;
  subcategories: Subcategory[];
  filteredList: string[] | number[];
  placeholder?: string;
  disabled?: boolean;
  name?: string;
}) {
  return (
    <Box sx={{ p: 3.5 }}>
      {filteredList.length > 0 ? (
        <BaseCheckboxGroup
          control={control}
          options={subcategories}
          name={name ?? "subcategory"}
          filteredList={filteredList}
          disabled={disabled}
        />
      ) : (
        <>
          {disabled ? null : (
            <Box sx={{ textAlign: "center" }}>{placeholder}</Box>
          )}
        </>
      )}
    </Box>
  );
}

export default SubcategoryCheckBox;
