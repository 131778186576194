import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Controller, FieldValues, Path } from "react-hook-form";

import { BaseTimeInputProps } from "../../types";
import { FormInputWrapper } from "../FormInputWrapper/FormInputWrapper";

export function BaseTimeInput<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  fullWidth,
  size,
  layoutType = "default",
  helperText,
  error,
  disabled
}: BaseTimeInputProps<TFieldValues>) {
  return (
    <FormInputWrapper width={194} layoutType={layoutType} fullWidth={fullWidth}>
      <Box>
        <Controller
          name={name as Path<TFieldValues>}
          render={({ field }) => (
            <TimePicker
              label={label}
              onChange={(date) => field.onChange(date)}
              value={field.value}
              ampm={true}
              ampmInClock={true}
              disabled={disabled}
              disableMaskedInput={true}
              inputFormat="HH:mm"
              renderInput={(params) => (
                <TextField
                  {...params}
                  size={size}
                  error={error ? true : false}
                  helperText={
                    (error?.message as string | undefined) ?? helperText
                  }
                  disabled={disabled}
                />
              )}
            />
          )}
          control={control}
        />
      </Box>
    </FormInputWrapper>
  );
}
