import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Controller, FieldValues, Path, useController } from "react-hook-form";

import { BaseAutoCompleteProps } from "../../types";
import { FormInputWrapper } from "../FormInputWrapper";

export default function BaseAutocomplete<TFieldValues extends FieldValues>({
  options,
  label,
  control,
  name,
  fullWidth = true,
  size = "medium",
  layoutType = "default",
  width,
  placeholder,
  renderTags,
  freeSolo,
  onChange,
  multiple,
  helperText,
  error,
  disabled,
  componentType = "default"
}: BaseAutoCompleteProps<TFieldValues>) {
  const { field: autoCompleteField } = useController({
    name: name as Path<TFieldValues>,
    control
  });

  const errorMessage = Array.isArray(error)
    ? error[0]?.message
    : error?.message;

  const defaultLabel = options.filter(
    (option) => option.value === autoCompleteField.value
  )?.[0]?.label;

  return (
    <FormInputWrapper
      layoutType={layoutType}
      fullWidth={fullWidth}
      width={width}
      label={label}
      size={size}
    >
      <Box sx={{ width: fullWidth ? "100%" : `${width ?? 200}px` }}>
        <Controller
          render={({ field: { onChange: onFieldChange } }) => (
            <Autocomplete
              fullWidth={fullWidth}
              options={options}
              renderTags={renderTags}
              freeSolo={freeSolo}
              multiple={multiple}
              disabled={disabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  fullWidth={fullWidth}
                  disabled={disabled}
                  size={size}
                  placeholder={placeholder}
                  error={error ? true : false}
                  helperText={errorMessage || helperText}
                />
              )}
              defaultValue={defaultLabel || undefined}
              onChange={
                onChange
                  ? onChange
                  : (_, data: { value: string; label: string }) => {
                      const changeValue =
                        componentType === "default" ? data?.value : data;
                      onFieldChange(changeValue);
                    }
              }
            />
          )}
          name={name as Path<TFieldValues>}
          control={control}
        />
      </Box>
    </FormInputWrapper>
  );
}
