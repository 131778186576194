import { useEffect } from "react";

import FormHelperText from "@mui/material/FormHelperText";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";

import { BaseSelect } from "../../input";
import { BaseSelectFilterProps } from "../../types/filter.type";
import { FilterTyphograpy } from "../FilterTyphography";
import { FilterWrapper } from "../FilterWrapper";

type FormValues = {
  [name: string]: string;
};

export function BaseSelectFilter({
  options,
  helperText,
  name,
  label,
  width,
  title,
  size,
  elevation = 0,
  variant = "elevation"
}: BaseSelectFilterProps) {
  const router = useRouter();

  const { control, watch, getValues, setValue } = useForm<FormValues>({
    defaultValues: {
      [name]: ""
    }
  });

  const watchSelect = watch(name);

  useEffect(() => {
    router.replace({
      pathname: router.basePath,
      query: { ...router.query, [name]: getValues(name) }
    });
  }, [watchSelect]);

  useEffect(() => {
    if (router.query[name]) setValue(name, router.query[name] as string);
  }, [router.isReady]);

  return (
    <FilterWrapper elevation={elevation} variant={variant}>
      <>
        {title && <FilterTyphograpy title={title} />}
        <BaseSelect
          name={name}
          options={options}
          label={label}
          control={control}
          width={width ?? 300}
          size={size}
        ></BaseSelect>
        <FormHelperText>{helperText}</FormHelperText>
      </>
    </FilterWrapper>
  );
}
