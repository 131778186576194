import { useState } from "react";

import WhereToVoteTwoToneIcon from "@mui/icons-material/WhereToVoteTwoTone";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { transformToKst } from "shared-utils";

import { BaseTable } from "../../table/BaseTable";
import {
  BaseTableColumns,
  BaseTableRenderCellParams
} from "../../table/BaseTable/types";

import { MapRidingTrackPoint, Position } from "./types";

type Props = {
  trackPoints: MapRidingTrackPoint[];
  onFocusTrackPoint?: (id: string, center: Position) => void;
};

export function RidingBikeRawInfo({ trackPoints, onFocusTrackPoint }: Props) {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const onClickTrackPoint = (_id: string, center: Position) => {
    onFocusTrackPoint && onFocusTrackPoint(_id, center);
    handleDialogClose();
  };

  const transformDataToString = (data?: null | any) => {
    if (data === null || data === undefined) return "";
    return `${data}`;
  };

  function createData(trackPoint: MapRidingTrackPoint) {
    const {
      _id,
      device_id,
      timestamp: raw_timestamp,
      type,
      position,
      condition,
      bike_battery: raw_bike_battery,
      device_battery: raw_device_battery,
      device_percentage: raw_device_percentage,
      device_voltage: raw_device_voltage
    } = trackPoint;

    const {
      locked: raw_locked,
      speed_zero: raw_speed_zero,
      is_pause: raw_is_pause,
      is_returning: raw_is_returning,
      fleet_connected: raw_fleet_connected
    } = condition || {};

    const locked = transformDataToString(raw_locked);
    const timestamp = transformToKst(raw_timestamp);
    const bike_battery = transformDataToString(raw_bike_battery);
    const device_battery = transformDataToString(
      raw_device_battery || raw_device_percentage
    );
    const device_voltage = transformDataToString(raw_device_voltage);
    const speed_zero = transformDataToString(raw_speed_zero);
    const is_pause = transformDataToString(raw_is_pause);
    const is_returning = transformDataToString(raw_is_returning);
    const fleet_connected = transformDataToString(raw_fleet_connected);

    return {
      _id,
      id: _id,
      device_id,
      timestamp,
      type,
      position,
      locked,
      bike_battery,
      device_battery,
      device_voltage,
      speed_zero,
      is_pause,
      is_returning,
      fleet_connected
    };
  }

  const rows = trackPoints.map((trackPoint) => createData(trackPoint));

  const columns: BaseTableColumns<MapRidingTrackPoint> = [
    {
      field: "device_id",
      headerName: "device_id",
      minWidth: 150
    },
    {
      field: "timestamp",
      headerName: "time",
      minWidth: 180,
      renderCell: (params: BaseTableRenderCellParams<MapRidingTrackPoint>) => {
        return <Box sx={{ fontSize: "13px" }}>{params.row.timestamp}</Box>;
      }
    },
    {
      field: "type",
      headerName: "type"
    },
    {
      field: "position",
      headerName: "위치",
      minWidth: 200,
      renderCell: (params: BaseTableRenderCellParams<MapRidingTrackPoint>) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              {params?.row?.position
                ?.map((point) => point.toFixed(6))
                .join(", ")}
            </Box>
            <Tooltip title="지도에서 이동">
              <IconButton
                onClick={() =>
                  onClickTrackPoint(params.row._id, params.row.position)
                }
              >
                <WhereToVoteTwoToneIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }
    },
    {
      field: "locked",
      headerName: "락열림"
    },
    {
      field: "bike_battery",
      headerName: "bike_battery"
    },
    {
      field: "device_battery",
      headerName: "device_battery",
      minWidth: 130
    },
    {
      field: "device_voltage",
      headerName: "device_voltage",
      minWidth: 130
    },
    {
      field: "speed_zero",
      headerName: "speed_zero",
      minWidth: 130
    },
    {
      field: "is_pause",
      headerName: "is_pause"
    },
    {
      field: "is_returning",
      headerName: "is_returning"
    },
    {
      field: "fleet_connected",
      headerName: "fleet_connected",
      minWidth: 130
    }
  ];
  return (
    <Paper
      sx={{ position: "absolute", top: "10px", left: "10px", zIndex: 2 }}
      elevation={0}
    >
      <Button onClick={handleClick} size="small" variant="outlined">
        라이딩 데이터
      </Button>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth={false}
        fullWidth
      >
        <DialogContent>
          <BaseTable rows={rows} columns={columns} />
        </DialogContent>
      </Dialog>
    </Paper>
  );
}
