import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FieldError, FieldValues, Path } from "react-hook-form";

import {
  defaultAfterNow,
  defaultDurationInpuDisabled,
  defaultDurationInputLabel,
  defaultDurationInputName,
  defaultDurationLayoutType
} from "../../constant/time.constant";
import { BaseDurationTimeInputProps } from "../../types";
import { BaseDayTimeInput } from "../BaseDayTimeInput";
import { FormInputWrapper } from "../FormInputWrapper/FormInputWrapper";

function BaseDurationTimeInput<TFieldValues extends FieldValues>({
  control,
  size,
  errors,
  helperText,
  layoutType = defaultDurationLayoutType,
  afterNow = defaultAfterNow,
  label = defaultDurationInputLabel,
  name = defaultDurationInputName,
  disabled = defaultDurationInpuDisabled,
  minDateTime
}: BaseDurationTimeInputProps<TFieldValues>) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
        <FormInputWrapper
          layoutType={layoutType}
          fullWidth
          label={label.group}
          size={size}
        >
          <>
            <StyleDurationWrapper>
              <BaseDayTimeInput
                control={control}
                name={name.start_at as Path<TFieldValues>}
                label={label.start_at}
                afterNow={afterNow}
                error={errors?.start_at as FieldError}
                size={size}
                disabled={disabled.start_at}
                helperText={helperText?.start_at}
                minDateTime={minDateTime}
              ></BaseDayTimeInput>

              <BaseDayTimeInput
                control={control}
                name={name.end_at as Path<TFieldValues>}
                label={label.end_at}
                afterNow={afterNow}
                error={errors?.end_at as FieldError}
                size={size}
                disabled={disabled.end_at}
                helperText={helperText?.end_at}
                minDateTime={minDateTime}
              ></BaseDayTimeInput>
            </StyleDurationWrapper>
            {helperText?.group || errors?.group ? (
              <FormHelperText>{helperText?.group}</FormHelperText>
            ) : null}
          </>
        </FormInputWrapper>
      </LocalizationProvider>
    </>
  );
}

const StyleDurationWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
  flexWrap: "wrap",
  alignItems: "start"
}));

export default BaseDurationTimeInput;
