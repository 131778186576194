import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { GridValidRowModel } from "@mui/x-data-grid/models";

import { BaseTable, BaseTableColumns } from "../table";

type HistoryAccordionProps<T extends GridValidRowModel> = {
  columns: BaseTableColumns<T>;
  rows?: T[];
  fetchRows: () => void;
  loading?: boolean;
};

export default function HistoryAccordion<T extends GridValidRowModel>({
  columns,
  rows,
  loading,
  fetchRows
}: HistoryAccordionProps<T>) {
  return (
    <Accordion elevation={0}>
      <AccordionSummary
        expandIcon={<StyledKeyboardArrowDownIcon />}
        sx={{ m: 0 }}
        onClick={fetchRows}
      >
        <StyledAccordionTitle>이력 펼치기 (최근 10개)</StyledAccordionTitle>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        {rows ? (
          <BaseTable rows={rows} columns={columns} isLoading={loading} />
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
}

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(
  ({ theme }) => ({
    color: theme.palette.primary.main
  })
);

const StyledAccordionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "13px"
}));
