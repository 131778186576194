import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";

export const StyledDialogContainer = styled(Dialog)(({ theme, open }) => ({
  textAlign: "center",
  visibility: open ? "visible" : "hidden",
  color: theme.palette.text.secondary,
  ".MuiPaper-root": {
    borderRadius: theme.spacing(0.5)
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  margin: "0 auto",
  flexGrow: 1
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  width: "320px",
  maxWidth: "100%",
  fontSize: "14px",
  whiteSpace: "pre-line",
  lineHeight: "1.7",
  textAlign: "left",
  wordBreak: "break-word"
}));
