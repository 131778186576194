import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SxProps, alpha, styled } from "@mui/material";
import Box from "@mui/material/Box";
import {
  green,
  grey,
  lightBlue,
  orange,
  pink,
  purple,
  red
} from "@mui/material/colors";

type BaseChipProps = {
  label: string;
  color?: "lightBlue" | "red" | "green" | "orange" | "purple" | "pink" | "grey";
  onClick?: (event) => void;
  statusIcon?: boolean;
  menuIcon?: boolean;
  size?: "small" | "medium";
  width?: number | string;
  sx?: SxProps;
};

const colors = {
  lightBlue,
  red,
  green,
  orange,
  purple,
  pink,
  grey
};
/**
 *
 * @param label 라벨
 * @param color 색상
 * @param onClick 클릭시 실행할 함수
 * @param statusIcon 원형 상태 아이콘 표시 여부
 * @param menuIcon 화살표 메뉴 아이콘 표시 여부
 * @param width 너비
 * @param sx sx
 * @example
 * ```tsx
 * <BaseChip label="성동권역" color="grey" />
 * <BaseChip label="결제취소" statusIcon color="red" />
 * <BaseChip label="1031003" menuIcon color="lightBlue" onClick={() => alert("클릭")} />
 * ```
 */
export const BaseChip = ({
  label,
  color = "lightBlue",
  onClick,
  statusIcon,
  menuIcon,
  width = "max-content",
  sx
}: BaseChipProps) => {
  const widthVal = typeof width === "number" ? `${width}px` : width;
  return (
    <StyledChip
      color={color}
      colors={colors}
      onClick={onClick}
      width={widthVal}
      sx={sx}
    >
      {statusIcon ? <StyledStatusIcon color={color} colors={colors} /> : null}
      <Box component="span">{label}</Box>
      {menuIcon ? (
        <StyledArrowContainer>
          <KeyboardArrowDownIcon />
        </StyledArrowContainer>
      ) : null}
    </StyledChip>
  );
};

type StyledChipProps = {
  color: BaseChipProps["color"];
  colors: typeof colors;
  onClick?: (event) => void;
  width: BaseChipProps["width"];
};

type StyledStatusIconProps = {
  color: BaseChipProps["color"];
  colors: typeof colors;
};

const StyledChip = styled(Box, {
  shouldForwardProp: (prop) => prop !== "color"
})<StyledChipProps>(({ color, colors, onClick, width }) => ({
  backgroundColor:
    color === "grey"
      ? alpha(colors[color!][100], 0.7)
      : alpha(colors[color!][50], 0.3),
  color: colors[color!][900],
  border: `1px solid ${
    color === "grey" ? colors[color][300] : colors[color!][100]
  }`,
  minHeight: "25px",
  padding: "2px 8px",
  fontWeight: 500,
  fontSize: "13px",
  borderRadius: "16px",
  lineHeight: "1.2em",
  display: "flex",
  alignItems: "center",
  cursor: onClick ? "pointer" : "default",
  width: width,
  justifyContent: "center"
}));

const StyledArrowContainer = styled(Box)({
  marginLeft: "2px",
  marginTop: "2px",
  svg: {
    fontSize: "17px"
  }
});

const StyledStatusIcon = styled(Box)<StyledStatusIconProps>(
  ({ color, colors }) => ({
    background: colors[color!][600],
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    marginRight: "4px"
  })
);
export default BaseChip;
