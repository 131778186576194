import { useEffect } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { removeArrayFromObject, addItemToObject } from "shared-utils";

import { BaseInput, BaseSelect } from "../../input";
import { BaseInputSelectFilterProps } from "../../types/filter.type";
import { FilterTyphograpy } from "../FilterTyphography";
import { FilterWrapper } from "../FilterWrapper";

type FormValues = {
  category: string;
  content: string;
};

export function BaseInputSelectFilter({
  options,
  title,
  elevation = 0,
  variant = "elevation"
}: BaseInputSelectFilterProps) {
  const router = useRouter();

  const { handleSubmit, control, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      category: "",
      content: ""
    }
  });

  const watchCategory = watch("category");

  const onSubmit = (data: FormValues) => {
    if (!data.category) return;

    const getQuery = () => {
      const getUnSelectedFilters = () => {
        const selectedFilter = data.category;
        return options
          .filter((item) => {
            return item.value !== selectedFilter;
          })
          .map((filtered) => filtered.value);
      };

      // 선택되지 않은 필터들을 query에서 제거합니다.
      const removeUnselectedFromQuery = () => {
        return removeArrayFromObject(router.query, getUnSelectedFilters());
      };
      // 선택된 필터를 query에 추가합니다.
      const getNewSelectedQuery = () => {
        return addItemToObject(
          removeUnselectedFromQuery(),
          data.category,
          data.content
        );
      };
      const query = getNewSelectedQuery();
      return query;
    };

    const query = getQuery();

    router.replace({
      pathname: router.basePath,
      query: query
    });
  };

  useEffect(() => {
    const findCategoryFromQuery = () => {
      const filters = options.map((filter) => filter.value);
      const queryKeys = Object.keys(router.query);

      const found = filters.filter((r) => {
        return queryKeys.indexOf(r) >= 0;
      })[0];

      return found;
    };

    const setDefaultValueByQuery = (found: string) => {
      if (found && typeof found === "string" && router.query[found]) {
        // query가 있을 경우, 해당 query를 기본값으로 설정합니다.
        setValue("category", found);
        const value = (router.query[found] as string | undefined) ?? "";
        setValue("content", value);
      } else {
        // 첫번째 option을 기본값으로 설정하므로, 먼저 표시될 필터를 options에서 가장 앞에 두어야 합니다.
        setValue("category", options[0].value);
      }
    };

    const foundCategory = findCategoryFromQuery();

    setDefaultValueByQuery(foundCategory);
  }, [router.isReady, router.query]);

  const helperTextBySelect = options.filter(
    (select) => select.value === watchCategory
  )[0]?.helperText;

  return (
    <FilterWrapper
      elevation={elevation}
      variant={variant}
      onSubmit={handleSubmit(onSubmit)}
    >
      <>
        {title && <FilterTyphograpy title={title} />}

        <Stack direction="row" gap={1} mt={1} flexWrap={"wrap"}>
          <BaseSelect
            name="category"
            options={options}
            label="카테고리"
            control={control}
            width={150}
          ></BaseSelect>

          <Box sx={{ flexGrow: 1 }}>
            <BaseInput name="content" control={control} fullWidth></BaseInput>
            <FormHelperText sx={{ px: 2 }}>{helperTextBySelect}</FormHelperText>
          </Box>
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "end", mb: 0.5, mr: 0.5 }}>
          <Button variant="text" type="submit">
            적용
          </Button>
        </Box>
      </>
    </FilterWrapper>
  );
}
