import { useMemo } from "react";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { NaverRidingMap } from "shared-utils";

import { RidingBikeRawInfo } from "./RidingBikeRawInfo";
import { MapRidingTrackPoint, MapRiding, Position, Geoblock } from "./types";
import useRidingPathMap from "./useRidingPathMap";

type Props = {
  riding: MapRiding | null;
  paths: Position[];
  trackPoints: MapRidingTrackPoint[];
  geoblocks: Geoblock[];
};

const ridingMapId = "riding-map";

export function RidingPathMap({
  riding,
  paths = [],
  trackPoints,
  geoblocks
}: Props) {
  const ridingMapInstance: NaverRidingMap = useMemo(
    () => new NaverRidingMap(),
    []
  );
  const {
    useInitializeMap,
    useUpdateStartPoint,
    useUpdateEndPoint,
    useUpdateRidingPath,
    useUpdateCenterPosition,
    useUpdateTrackPoints,
    onFocusTrackPoint,
    updateGeoblock
  } = useRidingPathMap(ridingMapId, ridingMapInstance);

  useInitializeMap();
  useUpdateStartPoint(riding);
  useUpdateEndPoint(riding);
  useUpdateRidingPath(paths);
  useUpdateTrackPoints(trackPoints);
  useUpdateCenterPosition(riding);
  updateGeoblock(geoblocks);

  return (
    <StyledRidingNaverMapContainer>
      {trackPoints?.length > 0 && (
        <RidingBikeRawInfo
          trackPoints={trackPoints}
          onFocusTrackPoint={onFocusTrackPoint}
        />
      )}

      <StyledMap id={ridingMapId} />
    </StyledRidingNaverMapContainer>
  );
}

const StyledRidingNaverMapContainer = styled(Box)`
  position: relative;
`;

const StyledMap = styled(Box)`
  width: 100%;
  height: 60vh;
  max-height: 800px;
`;
