import { useMemo } from "react";

import { FormHelperText } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { transformToKst } from "shared-utils";
import { v1 } from "uuid";

import BoardItemContainer from "../../container/BoardItemContainer";
import { BaseTable } from "../BaseTable";

export type Statistics = {
  total_count: number;
  field_count: number;
  battery_avg: number;
  available_count: number;
  repair_count: number;
  retrieved_count: number;
  rebalance_count: number;
  discharge_count: number;
  riding_count: number;
  cached_at: string;
};

export default function StatisticsTableGroup({
  title,
  code,
  rows
}: {
  title?: string;
  code?: string;
  rows: Statistics[];
}) {
  const renderCell = (params) => {
    return (
      <Typography sx={{ fontSize: "12px" }}>
        {params.value?.toLocaleString()}
      </Typography>
    );
  };
  const columns = [
    {
      headerName: "총 기기",
      field: "total_count",
      renderCell
    },
    {
      headerName: "필드 기기",
      field: "field_count",
      renderCell
    },
    {
      headerName: "배터리 평균",
      field: "battery_avg",
      renderCell
    }
  ];

  const statusColumns = useMemo(
    () => [
      {
        headerName: "정상",
        field: "available_count",
        minWidth: 65,
        renderCell
      },
      {
        headerName: "고장",
        field: "repair_count",
        minWidth: 65,
        renderCell
      },
      {
        headerName: "수거",
        field: "retrieved_count",
        minWidth: 65,
        renderCell
      },
      {
        headerName: "재배치",
        field: "rebalance_count",
        minWidth: 65,
        renderCell
      },
      {
        headerName: "방전",
        field: "discharge_count",
        minWidth: 65,
        renderCell
      },
      {
        headerName: "이용중",
        field: "riding_count",
        minWidth: 65,
        renderCell
      }
    ],
    []
  );
  return (
    <>
      <BoardItemContainer title={`${title} ${code ? `(${code})` : ""}`}>
        <Stack>
          {rows && rows.length && (
            <>
              <BaseTable
                elevation={0}
                columns={columns}
                rows={rows.map((row) => ({ ...row, id: v1() }))}
                rowHeight={50}
              />
              <BaseTable
                elevation={0}
                columns={statusColumns}
                rows={rows.map((row) => ({ ...row, id: v1() }))}
                rowHeight={50}
              />
              <FormHelperText>
                업데이트 시간: {transformToKst(rows[0].cached_at)}
              </FormHelperText>
            </>
          )}
        </Stack>
      </BoardItemContainer>
    </>
  );
}
