import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState
} from "react";

type FormGeneratorReturns = {
  getValues: () => Record<string, unknown> | void;
  errors: Record<string, unknown>;
};

type FormGeneratorContextType =
  | [FormGeneratorReturns, Dispatch<SetStateAction<FormGeneratorReturns>>]
  | undefined;

const FormGeneratorContext = createContext<FormGeneratorContextType>(undefined);

export const useFormGeneratorContext = () => {
  // FormGenerator 내부에서 사용하는 context, set함수도 같이 반환
  const context = useContext(FormGeneratorContext);

  if (!context) {
    return;
  }
  return context;
};

export const useFormGeneratorContextValue = () => {
  // shared-ui 패키지를 사용하는 곳에서 사용하는 context, set함수는 반환하지 않음
  const context = useFormGeneratorContext();

  if (!context) {
    throw new Error(
      "useFormGeneratorContext must be used within a FormGeneratorProvider"
    );
  }
  return context?.[0];
};

export const FomrGeneratorProvider = ({ children }) => {
  const [formReturns, setFormReturns] = useState<FormGeneratorReturns>({
    // react hook form에서 제공하는 getValues 함수등을 담고 있음. 다른 함수나 값이 필요하다면 추가 예정
    getValues: () => {},
    errors: {}
  });
  return (
    <FormGeneratorContext.Provider value={[formReturns, setFormReturns]}>
      {children}
    </FormGeneratorContext.Provider>
  );
};
