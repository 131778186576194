import Stack from "@mui/material/Stack";
import {
  Control,
  FieldError,
  FieldErrors,
  FieldValues,
  UseFormSetValue
} from "react-hook-form";
import { v4 } from "uuid";

import {
  Base64FileInput,
  BaseInput,
  BaseMultipleSelect,
  BaseSelect,
  BaseCheckboxGroup,
  BaseAutocomplete,
  BaseDropdownInputGroup,
  BaseRadioGroup,
  BaseFileInput,
  BaseCsvInput,
  BaseDayTimeInput,
  BaseCrontabGroup,
  BaseAreaSelect,
  BaseImageInput
} from "../input";
import { BaseDateDurationInput } from "../input/BaseDateDurationInput/BaseDateDurationInput";
import { BaseTimeDurationInput } from "../input/BaseTimeDurationInput/BaseTimeDurationInput";
import { BaseInputTypeEnum, InputFieldInfo } from "../types";

type InputRendererProps<T extends FieldValues> = {
  inputInfos: InputFieldInfo<T>[];
  errors: FieldErrors;
  control: Control<T>;
  setValue: UseFormSetValue<T>;
};

export const InputRenderer = <T extends FieldValues>({
  inputInfos,
  errors,
  control,
  setValue
}: InputRendererProps<T>) => {
  const getInputAdditionalProps = (inputInfo: InputFieldInfo<T>) => {
    const commonAdditionalOptions = {
      key: inputInfo.name || v4(),
      control,
      error: errors[inputInfo.name] as FieldError,
      fullWidth: inputInfo.fullWidth === undefined ? true : inputInfo.fullWidth,
      layoutType: inputInfo.layoutType || "horizontal",
      size: inputInfo.size || "small",
      setValue,
      errors: errors as FieldErrors,
      disabled: inputInfo.disabled
    };

    if (inputInfo.inputType === BaseInputTypeEnum.DateRangeInput) {
      return {
        ...commonAdditionalOptions,
        disabledStartDate: inputInfo.disabledStartDate,
        disabledEndDate: inputInfo.disabledEndDate
      };
    }

    return commonAdditionalOptions;
  };

  return (
    <>
      {inputInfos.map((inputInfo) => {
        if (inputInfo.hidden) return null;

        switch (inputInfo.inputType) {
          case BaseInputTypeEnum.Input:
            return (
              <BaseInput
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
          case BaseInputTypeEnum.Select:
            return (
              <BaseSelect
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
          case BaseInputTypeEnum.MultiSelect:
            return (
              <BaseMultipleSelect
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
          case BaseInputTypeEnum.Base64Input:
            return (
              <Base64FileInput
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
          case BaseInputTypeEnum.CheckboxGroup:
            return (
              <BaseCheckboxGroup
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
          case BaseInputTypeEnum.FileInput:
            return (
              <BaseFileInput
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
          case BaseInputTypeEnum.RadioGroup:
            return (
              <BaseRadioGroup
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
          case BaseInputTypeEnum.Autocomplete:
            return (
              <BaseAutocomplete
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
          case BaseInputTypeEnum.CsvInput:
            return (
              <BaseCsvInput
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
          case BaseInputTypeEnum.DayTime:
            return (
              <BaseDayTimeInput
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
          case BaseInputTypeEnum.Crontab:
            return (
              <BaseCrontabGroup
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
          case BaseInputTypeEnum.DropdownInputGroup:
            return (
              <BaseDropdownInputGroup
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              >
                <Stack spacing={2}>
                  {inputInfo.childInputInfos?.length > 0 ? (
                    <InputRenderer
                      inputInfos={inputInfo.childInputInfos}
                      errors={errors}
                      control={control}
                      setValue={setValue}
                    />
                  ) : null}
                </Stack>
              </BaseDropdownInputGroup>
            );
          case BaseInputTypeEnum.DateRangeInput:
            return (
              <BaseDateDurationInput
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
                control={control as Control<FieldValues>}
              />
            );
          case BaseInputTypeEnum.AreaSelect:
            return (
              <BaseAreaSelect
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
          case BaseInputTypeEnum.ImageInput:
            return (
              <BaseImageInput
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
          case BaseInputTypeEnum.BaseTimeDurationInput:
            return (
              <BaseTimeDurationInput
                {...inputInfo}
                {...getInputAdditionalProps(inputInfo)}
              />
            );
        }
      })}
    </>
  );
};
