import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Stack from "@mui/system/Stack";
import { FieldValues, Path, useController } from "react-hook-form";

import { ThumnailPhotoTableData } from "../../tableData";
import { BaseFileInputProps } from "../../types";
import { FormInputWrapper } from "../FormInputWrapper";

export default function BaseFileInput<T extends FieldValues>({
  layoutType,
  fullWidth,
  width,
  control,
  size,
  error,
  label,
  helperText,
  disabled,
  name,
  onChange,
  accept,
  fileName,
  isLoading
}: BaseFileInputProps<T>) {
  const errorMessage = Array.isArray(error)
    ? error[0]?.message
    : error?.message;

  const { field } = useController({
    name: name as Path<T>,
    control
  });

  return (
    <FormInputWrapper
      layoutType={layoutType}
      fullWidth={fullWidth}
      width={width}
      label={label}
      size={size}
    >
      <Stack sx={{ width: "100%" }}>
        <Stack spacing={2}>
          <Stack sx={{ width: "100%" }}>
            <Stack direction="row">
              <StyledFileUploadLabel
                size={size}
                component="label"
                disabled={disabled}
                error={errorMessage ? true : false}
              >
                <input
                  onChange={onChange}
                  hidden
                  accept={accept}
                  multiple
                  type="file"
                  disabled={disabled}
                />
                <Box sx={{ display: "grid", gridTemplateColumns: "0.3fr 3fr" }}>
                  <StyledFileUploadText disabled={disabled}>
                    파일 선택
                  </StyledFileUploadText>
                  <StyledFileNameText>{fileName}</StyledFileNameText>
                </Box>
              </StyledFileUploadLabel>
            </Stack>
            <FormHelperText error={errorMessage ? true : false}>
              {errorMessage || helperText}
            </FormHelperText>
          </Stack>
          {field.value && accept === "image/*" ? (
            <ThumnailPhotoTableData
              fullImageUrl={field.value ?? ""}
              thumnailUrl={field.value ?? ""}
              isLoading={isLoading}
              zoomIcon
              maxWidth="large"
            />
          ) : null}
        </Stack>
      </Stack>
    </FormInputWrapper>
  );
}

type StyledFileUploadLabelProps = {
  size?: "small" | "medium";
  component: string;
  disabled?: boolean;
  error: boolean;
};

type StyledUploadTextProps = {
  disabled?: boolean;
};

const StyledFileUploadLabel = styled("label", {
  shouldForwardProp: (prop) =>
    prop !== "size" &&
    prop !== "error" &&
    prop !== "disabled" &&
    prop !== "component"
})<StyledFileUploadLabelProps>(({ theme, size, error }) => ({
  display: "block",
  alignItems: "center",
  justifyContent: "start",
  width: "100%",
  background: theme.palette.common.white,
  border: `1px solid ${
    error ? theme.palette.error.main : theme.palette.grey[400]
  }`,
  borderRadius: theme.spacing(0.5),
  padding: "8.5px 14px",
  boxShadow: "none",
  color: `${theme.palette.grey[400]}`,
  height: size === "small" ? "40px" : "56px",
  fontWeight: 400
}));

const StyledFileUploadText = styled("span", {
  shouldForwardProp: (prop) => prop !== "disabled"
})<StyledUploadTextProps>(({ theme, disabled }) => ({
  padding: "2px",
  borderRadius: theme.spacing(0.5),
  border: `1px solid ${
    disabled ? theme.palette.grey[400] : theme.palette.grey[800]
  }`,
  marginRight: theme.spacing(1),
  color: disabled ? theme.palette.grey[400] : theme.palette.grey[800],
  fontSize: "12px",
  width: "60px",
  textAlign: "center"
}));

const StyledFileNameText = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  maxWidth: "500px",
  color: theme.palette.grey[600]
}));
