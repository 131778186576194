import { useEffect } from "react";

import { useSystemDialog } from "../form-generator/useSystemDialog";

import { useSpecificDialogContext } from "./SpecificDialogProvider";

const useSpecificDialog = <T>() => {
  const [specificDialog, setSpecificDialog] = useSpecificDialogContext();

  const { systemConfirm } = useSystemDialog();

  const observeIsDirty = (isDirty = false) => {
    useEffect(() => {
      setSpecificDialog((prev) => ({ ...prev, shouldConfirmChanges: isDirty }));
    }, [isDirty]);
  };

  const setShouldConfirmChanges = (shouldConfirmChanges: boolean) => {
    setSpecificDialog((prev) => ({ ...prev, shouldConfirmChanges }));
  };

  const handleDetailClick = (params: { row: T }) => {
    setSpecificDialog((prev) => ({
      ...prev,
      dialogType: "edit",
      isDialogOpen: true,
      selected: params.row
    }));
  };

  const handleCreateClick = () => {
    setSpecificDialog((prev) => ({
      ...prev,
      dialogType: "create",
      isDialogOpen: true,
      selected: null
    }));
  };

  const handleClose = async () => {
    setSpecificDialog((prev) => ({ ...prev, isDialogOpen: false }));
  };

  const handleCloseWithConfirmChanges = async () => {
    if (specificDialog.shouldConfirmChanges) {
      const confirmed = await systemConfirm(
        "작성중인 내용을 저장하지 않고 창을 닫습니다"
      );
      if (confirmed)
        setSpecificDialog((prev) => ({
          ...prev,
          isDialogOpen: false,
          shouldConfirmChanges: false
        }));
      return;
    }

    setSpecificDialog((prev) => ({ ...prev, isDialogOpen: false }));
  };

  const resetToDefaultValues = () => {
    setSpecificDialog((prev) => ({
      ...prev,
      isDialogOpen: false,
      dialogType: "create",
      selected: null,
      shouldConfirmChanges: false,
      isCopied: false,
      statusActive: false,
      isExpired: false
    }));
  };

  const setIsExpired = (isExpired: boolean) => {
    setSpecificDialog((prev) => ({ ...prev, isExpired }));
  };

  useEffect(() => {
    return () => {
      resetToDefaultValues();
    };
  }, []);

  const setIsDialogOpen = (isDialogOpen: boolean) => {
    setSpecificDialog((prev) => ({ ...prev, isDialogOpen }));
  };

  const setDialogType = (dialogType: "create" | "edit") => {
    setSpecificDialog((prev) => ({ ...prev, dialogType }));
  };

  const setSelected = (selected: T | null) => {
    setSpecificDialog((prev) => ({ ...prev, selected }));
  };

  const setIsCopiedTrue = () => {
    setSpecificDialog((prev) => ({
      ...prev,
      dialogType: "create",
      isCopied: true
    }));
  };

  const setStatusActive = (isActive: boolean) => {
    setSpecificDialog((prev) => ({ ...prev, statusActive: isActive }));
  };

  return {
    setIsDialogOpen,
    handleClose,
    handleCloseWithConfirmChanges,
    setDialogType,
    setSelected,
    observeIsDirty,
    handleDetailClick,
    handleCreateClick,
    setIsCopiedTrue,
    setStatusActive,
    setIsExpired,
    setShouldConfirmChanges,
    ...specificDialog
  };
};

export default useSpecificDialog;
