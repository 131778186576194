import { useMemo } from "react";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import { accountState } from "@store/recoil/accountRecoil";
import { useRecoilValue } from "recoil";

function SidebarProfile() {
  const account = useRecoilValue(accountState);

  const userInfoTitle = useMemo(
    () => (account ? `${account.name}` : ""),
    [account]
  );

  return (
    <StyledListItem disableGutters>
      <StyledButton disableRipple>
        <StyledIcon>
          <AccountCircleOutlinedIcon />
        </StyledIcon>
        <StyledTitle>{userInfoTitle}</StyledTitle>
      </StyledButton>
    </StyledListItem>
  );
}

const StyledListItem = styled(ListItem)(() => ({
  display: "block",
  paddingTop: "0",
  paddingBottom: "0"
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  justifyContent: "flex-start",
  width: "100%",
  color: theme.palette.grey[800]
}));

const StyledIcon = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginRight: "10px"
}));

const StyledTitle = styled(Box)(() => ({
  marginRight: "auto",
  textTransform: "none"
}));

export default SidebarProfile;
