import { useEffect } from "react";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import { useRouter } from "next/router";
import { useForm, Controller } from "react-hook-form";
import { removeItemFromObject, removeItemsFromObject } from "shared-utils";

import { BaseRadioGroupFilterProps } from "../../types/filter.type";
import { FilterTyphograpy } from "../FilterTyphography";
import { FilterWrapper } from "../FilterWrapper";

type FormValues = {
  radioGroup: string;
};

export function BaseRadioGroupFilter({
  options,
  name,
  title,
  elevation = 0,
  variant = "elevation"
}: BaseRadioGroupFilterProps) {
  const router = useRouter();
  const { setValue, control, watch } = useForm<FormValues>({
    defaultValues: {
      radioGroup: ""
    }
  });

  const handleChange = (value: string) => {
    const isValueExist = !!value;
    if (isValueExist) {
      router.replace({
        pathname: router.basePath,
        query: {
          ...removeItemFromObject(router.query, "page"),
          [name]: value
        }
      });
    } else {
      const query = removeItemsFromObject(router.query, [name, "page"]);
      router.replace({
        pathname: router.basePath,
        query
      });
    }
  };

  const radioGroupWatch = watch("radioGroup");

  useEffect(() => {
    if (router.isReady) {
      const queryKeys = Object.keys(router.query);
      if (queryKeys.includes(name)) {
        setValue("radioGroup", router.query[name] as string);
      }
    }
  }, [router.isReady]);

  useEffect(() => {
    handleChange(radioGroupWatch);
  }, [radioGroupWatch]);

  return (
    <FilterWrapper elevation={elevation} variant={variant}>
      <>
        <FormControl>
          {title && <FilterTyphograpy title={title} />}
          <Controller
            render={({ field }) => (
              <StyledRadioGroup {...field}>
                <FormControlLabel value={""} control={<Radio />} label="전체" />
                {options.map((item) => {
                  return (
                    <FormControlLabel
                      value={item.value}
                      control={<Radio />}
                      key={item.label}
                      label={item.label}
                    />
                  );
                })}
              </StyledRadioGroup>
            )}
            name="radioGroup"
            control={control}
          />
        </FormControl>
      </>
    </FilterWrapper>
  );
}

const StyledRadioGroup = styled(RadioGroup)`
  flex-direction: row;
`;
