import { ReactNode } from "react";

import Paper from "@mui/material/Paper";
import { SxProps } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export default function BoardItemContainer({
  sx,
  children,
  title,
  elevation = 0
}: {
  sx?: SxProps;
  children: ReactNode;
  title?: string;
  elevation?: number;
}) {
  return (
    <Paper
      elevation={elevation}
      variant="outlined"
      sx={{ marginTop: 1, p: 2, ...sx }}
    >
      {title ? (
        <Typography variant="h6" sx={{ m: 1, fontWeight: "600" }}>
          {title}
        </Typography>
      ) : null}
      {children}
    </Paper>
  );
}
