import { useState, useEffect, createContext } from "react";

import { useSystemDialogContext } from "../systemDialogProvider/SystemDialogProvider";

type systemDialogTypes = "confirm" | "prompt" | "alert";
// replace with context api of systemDialogRecoil

export const useSystemDialog = () => {
  const [systemDialogState, setSystemDialogState] = useSystemDialogContext();
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const reset = () => {
    setSystemDialogState({
      title: "",
      content: "",
      onConfirm: null,
      onCancel: null,
      open: null
    });
    setNeedsCleanup(false);
  };

  const getSystemDialogResolve = (type: systemDialogTypes) => {
    let resolve: (value?: string) => boolean | string = () => {
      reset();
      return true;
    };

    if (type === "prompt") {
      resolve = (value: string) => {
        reset();
        return value;
      };
    }

    return resolve;
  };

  const systemDialog = (
    message: string,
    title?: string,
    type: systemDialogTypes = "alert"
  ) => {
    const promise = new Promise((resolve, reject) => {
      setSystemDialogState({
        content: message ?? "",
        title: title ?? "",
        open: type,
        onConfirm: resolve,
        onCancel: reject
      });
      setNeedsCleanup(true);
    });

    return promise.then(getSystemDialogResolve(type), () => {
      reset();
      return false;
    });
  };

  useEffect(() => {
    return () => {
      // confirm dialog가 뒤로가기시에도 살아있지 않도록 클린업에서 처리
      if (systemDialogState.onCancel && needsCleanup) {
        systemDialogState.onCancel();
      }
    };
  }, [systemDialog, needsCleanup]);

  const systemConfirm = (message: string, title = "") => {
    return systemDialog(message, title, "confirm");
  };
  const systemPrompt = (message: string, title = "") => {
    return systemDialog(message, title, "prompt");
  };
  const systemAlert = (message: string, title = "") => {
    return systemDialog(message, title, "alert");
  };

  return {
    ...systemDialogState,
    systemConfirm,
    systemPrompt,
    systemAlert
  };
};
