import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

type Props = {
  open: boolean;
  onClose: () => void;
  fullImageUrl?: string;
};

export function ThumnailDialog({ open, onClose, fullImageUrl }: Props) {
  return (
    <>
      <StyledThumnailDialog open={open} onClose={onClose}>
        <StyledDialogTitle>
          <IconButton
            sx={{ ml: "auto", mb: 0.5 }}
            aria-label="delete"
            onClick={onClose}
          >
            <CloseIcon sx={{ color: "#fff" }}></CloseIcon>
          </IconButton>
        </StyledDialogTitle>
        <StyledDialogContent>
          <img src={fullImageUrl ?? ""} style={{ maxWidth: "100%" }} />
        </StyledDialogContent>
      </StyledThumnailDialog>
    </>
  );
}

const StyledThumnailDialog = styled(Dialog)`
  & .MuiPaper-root {
    background: transparent !important;
    box-shadow: none;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: end;
  padding: 0;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0;
`;
