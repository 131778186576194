import {
  BaseCheckboxFilterProps,
  BaseInputSelectFilterProps,
  BaseRadioGroupFilterProps,
  BaseSelectFilterProps
} from "./filter.type";

export enum BaseFilterEnum {
  CheckboxGroup = "CheckboxGroup",
  IputSelect = "IputSelect",
  RadioGroup = "RadioGroup",
  Select = "Select"
}

export type BaseCheckboxFilterGeneratorProps = {
  filterType: BaseFilterEnum.CheckboxGroup;
} & BaseCheckboxFilterProps;

export type BaseInputSelectFilterGeneratorProps = {
  filterType: BaseFilterEnum.IputSelect;
} & BaseInputSelectFilterProps;

export type BaseRadioGroupFilterGeneratorProps = {
  filterType: BaseFilterEnum.RadioGroup;
} & BaseRadioGroupFilterProps;

export type BaseSelectFilterGeneratorProps = {
  filterType: BaseFilterEnum.Select;
} & BaseSelectFilterProps;

export type FilterInputInfos =
  | BaseCheckboxFilterGeneratorProps
  | BaseInputSelectFilterGeneratorProps
  | BaseRadioGroupFilterGeneratorProps
  | BaseSelectFilterGeneratorProps;
