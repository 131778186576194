import { useState } from "react";

import accountApi from "@network/account/accountApi";
import { accountState } from "@store/recoil/accountRecoil";
import { setCookie, removeAllCookies } from "@utils/cookie";
import { useRouter } from "next/router";
import { useSetRecoilState } from "recoil";
import { SigninParams } from "types/account.types";

const useAuth = () => {
  const [isLoading, setIsLoading] = useState(false);

  const setAccountState = useSetRecoilState(accountState);
  const router = useRouter();

  const postSignin = async (params: SigninParams) => {
    try {
      setIsLoading(true);

      const data = await accountApi().signin(params);

      const { id, in_testing, level, name, role, token } = data;

      setCookie("access_token", token.access_token, {
        path: "/",
        secure: true,
        maxAge: token.expires_in
      });

      setCookie("refresh_token", token.refresh_token, {
        path: "/",
        secure: true
      });

      setAccountState({ id, in_testing, level, name, role });

      router.replace("/");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const postSignout = async () => {
    try {
      accountApi().signout();
      removeAllCookies();
      router.push("/login");
    } catch (error) {
      console.error(error);
    }
  };

  return {
    isLoading,
    setIsLoading,
    postSignin,
    postSignout
  };
};

export default useAuth;
