import { SxProps } from "@mui/material";
import { FieldValues, SubmitErrorHandler } from "react-hook-form";
import { AnySchema } from "yup";

import {
  Base64FileInputProps,
  BaseAreaSelectProps,
  BaseAutoCompleteProps,
  BaseCheckboxGroupProps,
  BaseCrontabType,
  BaseCsvInputProps,
  BaseDateDurationInputProps,
  BaseDayTimeProps,
  BaseDropdownInputGroupProps,
  BaseFileInputProps,
  BaseImageInputProps,
  BaseInputProps,
  BaseMultipleSelectProps,
  BaseRadioGroupProps,
  BaseSelectProps,
  BaseTimeDurationInputProps
} from "./form.type";
export type BaseGeneratorProps<T> = Omit<T, "control"> & {
  validation?: AnySchema;
  defaultValue?: any;
  hidden?: boolean;
  label: string; // required
  disableOnEdit?: (data: T) => boolean;
};

export enum BaseInputTypeEnum {
  "Input" = "Input",
  "Select" = "Select",
  "MultiSelect" = "MultiSelect",
  "Base64Input" = "Base64Input",
  "CheckboxGroup" = "CheckboxGroup",
  "FileInput" = "FileInput",
  "ImageInput" = "ImageInput",
  "RadioGroup" = "RadioGroup",
  "Autocomplete" = "Autocomplete",
  "CsvInput" = "CsvInput",
  "DayTime" = "DayTime",
  "Crontab" = "Crontab",
  "DropdownInputGroup" = "DropdownInputGroup",
  "DateRangeInput" = "DateRangeInput",
  "AreaSelect" = "AreaSelect",
  "BaseTimeDurationInput" = "BaseTimeDurationInput"
}

export type BaseInputFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.Input;
} & BaseGeneratorProps<BaseInputProps<T>>;

export type BaseSelectFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.Select;
} & BaseGeneratorProps<BaseSelectProps<T>>;

export type BaseMultiSelectFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.MultiSelect;
} & BaseGeneratorProps<BaseMultipleSelectProps<T>>;

export type Base64InputFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.Base64Input;
} & BaseGeneratorProps<Base64FileInputProps<T>>;

export type BaseCheckboxGroupFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.CheckboxGroup;
} & BaseGeneratorProps<BaseCheckboxGroupProps<T>>;

export type BaseFileInputFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.FileInput;
} & BaseGeneratorProps<BaseFileInputProps<T>>;

export type BaseRadioGroupFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.RadioGroup;
} & BaseGeneratorProps<BaseRadioGroupProps<T>>;

export type BaseAutoCompleteFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.Autocomplete;
} & BaseGeneratorProps<BaseAutoCompleteProps<T>>;

export type BaseCsvInputFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.CsvInput;
} & BaseGeneratorProps<BaseCsvInputProps<T>>;

export type BaseDayTimeFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.DayTime;
} & BaseGeneratorProps<BaseDayTimeProps<T>>;

export type BaseCrontabFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.Crontab;
} & BaseGeneratorProps<BaseCrontabType<T>>;

export type BaseDropdownInputGroupFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.DropdownInputGroup;
} & BaseGeneratorProps<BaseDropdownInputGroupProps<T>> & {
    childInputInfos: InputFieldInfo<T>[];
  };

export type BaseDateDurationInputFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.DateRangeInput;
  disableStartDateOnEdit?: (data: T) => boolean;
  disableEndDateOnEdit?: (data: T) => boolean;
} & BaseGeneratorProps<BaseDateDurationInputProps<T>>;

export type BaseAreaSelectFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.AreaSelect;
} & BaseGeneratorProps<BaseAreaSelectProps<T>>;

export type BaseImageInputFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.ImageInput;
} & BaseGeneratorProps<BaseImageInputProps<T>>;

export type BaseTimeDurationInputFormGeneratorInfo<T extends FieldValues> = {
  inputType: BaseInputTypeEnum.BaseTimeDurationInput;
} & BaseGeneratorProps<BaseTimeDurationInputProps<T>>;

export type InputFieldInfo<T extends FieldValues> =
  | BaseInputFormGeneratorInfo<T>
  | BaseSelectFormGeneratorInfo<T>
  | BaseMultiSelectFormGeneratorInfo<T>
  | Base64InputFormGeneratorInfo<T>
  | BaseCheckboxGroupFormGeneratorInfo<T>
  | BaseFileInputFormGeneratorInfo<T>
  | BaseImageInputFormGeneratorInfo<T>
  | BaseRadioGroupFormGeneratorInfo<T>
  | BaseAutoCompleteFormGeneratorInfo<T>
  | BaseCsvInputFormGeneratorInfo<T>
  | BaseDayTimeFormGeneratorInfo<T>
  | BaseCrontabFormGeneratorInfo<T>
  | BaseDropdownInputGroupFormGeneratorInfo<T>
  | BaseDateDurationInputFormGeneratorInfo<T>
  | BaseAreaSelectFormGeneratorInfo<T>
  | BaseTimeDurationInputFormGeneratorInfo<T>;

export type SubmitOptions = {
  onError?: (error: unknown) => void;
  onSuccess?: (data: unknown) => void;
};

export type FomrGeneratorProps<T extends FieldValues> = {
  inputInfos: InputFieldInfo<T>[];
  onSubmit?: (data: T, options?: SubmitOptions) => void;
  onCreate?: (data: T, options?: SubmitOptions) => void;
  onEdit?: (data: T, options?: SubmitOptions) => void;
  onDelete?: (data: T, options?: SubmitOptions) => void;
  onCancel?: (val?: unknown) => void;
  onError?: SubmitErrorHandler<T>;
  title?: string;
  mode: "create" | "edit" | "delete";
  elevation?: number;
  setShouldConfirmChanges?: (val: boolean) => void;
  closeDialog?: () => void;
  hasSubmitButton?: boolean;
  editActionText?: string;
  createActionText?: string;
  deleteActionText?: string;
  submitButtonSx?: SxProps;
  p?: number;
  spacing?: number;
};
