import { useState } from "react";

const useImageUpload = () => {
  const [imageName, setImageName] = useState("");
  const [imageBase64, setImageBase64] = useState("");
  const [imageType, setImageType] = useState("");

  const handleImageFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const fileType = file?.type;
    const reader = new FileReader();

    reader.onload = () => {
      setImageName(file!.name);
      setImageBase64(reader.result as string);
      setImageType(fileType ?? "");
    };

    if (file === undefined) return;

    reader.readAsDataURL(file as Blob);
  };

  return {
    imageName,
    imageBase64,
    imageType,
    handleImageFile,
    setImageName,
    setImageBase64
  };
};

export default useImageUpload;
