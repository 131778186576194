import { useState } from "react";

import { FieldValues } from "react-hook-form";

import { Base64FileInputProps } from "../../types";
import { BaseFileInput } from "../BaseFileInput";

export const Base64FileInput = <T extends FieldValues>({
  control,
  name,
  layoutType,
  fullWidth,
  width,
  size,
  error,
  label,
  helperText,
  disabled,
  setValue,
  placeholder,
  accept
}: Base64FileInputProps<T>) => {
  const [fileName, setFileName] = useState<string>();

  const onFileUpload = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result as string;
      setValue &&
        setValue(name, base64, {
          shouldDirty: true,
          shouldValidate: true
        });
    };

    setFileName(file.name);
  };

  return (
    <BaseFileInput
      control={control}
      name={name}
      layoutType={layoutType}
      fullWidth={fullWidth}
      width={width}
      size={size}
      error={error}
      label={label}
      helperText={helperText}
      disabled={disabled}
      setValue={setValue}
      placeholder={placeholder}
      fileName={fileName}
      onChange={onFileUpload}
      accept={accept}
    />
  );
};
