import { Stack } from "@mui/material";
import { v4 } from "uuid";

import { BaseCheckboxGroupFilter } from "../filter/BaseCheckboxGroupFilter";
import { BaseInputSelectFilter } from "../filter/BaseInputSelectFilter";
import { BaseRadioGroupFilter } from "../filter/BaseRadioGroupFilter";
import { BaseSelectFilter } from "../filter/BaseSelectFilter/BaseSelectFilter";
import {
  BaseFilterEnum,
  FilterInputInfos
} from "../types/filterGenerator.type";
export const FilterGenerator = ({
  filterInputInfos
}: {
  filterInputInfos: FilterInputInfos[];
}) => {
  const getCommonAdditionalProps = (filterInfo: FilterInputInfos) => {
    return {
      variant: "outlined" as const,
      key:
        "name" in filterInfo
          ? filterInfo.name
          : filterInfo.options[0].value + filterInfo.title
    };
  };

  return (
    <Stack spacing={1.5}>
      <>
        {filterInputInfos.map((filterInfo) => {
          switch (filterInfo.filterType) {
            case BaseFilterEnum.CheckboxGroup:
              return (
                <BaseCheckboxGroupFilter
                  {...filterInfo}
                  {...getCommonAdditionalProps(filterInfo)}
                />
              );
            case BaseFilterEnum.RadioGroup:
              return (
                <BaseRadioGroupFilter
                  {...filterInfo}
                  {...getCommonAdditionalProps(filterInfo)}
                />
              );
            case BaseFilterEnum.IputSelect:
              return (
                <BaseInputSelectFilter
                  {...filterInfo}
                  {...getCommonAdditionalProps(filterInfo)}
                />
              );
            case BaseFilterEnum.Select:
              return (
                <BaseSelectFilter
                  {...filterInfo}
                  {...getCommonAdditionalProps(filterInfo)}
                />
              );
            default:
              return null;
          }
        })}
      </>
    </Stack>
  );
};
