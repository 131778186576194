export const defaultDurationInputLabel = {
  group: "적용 기간",
  start_at: "시작",
  end_at: "종료"
};

export const defaultDurationInputName = {
  start_at: "start_at",
  end_at: "end_at"
};

export const defaultDurationInpuDisabled = {
  start_at: false,
  end_at: false
};

export const defaultAfterNow = false;

export const defaultDurationLayoutType = "default";
