import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { FieldValues } from "react-hook-form";

import { BaseDateDurationInputProps } from "../../types";
import { BaseDayTimeInput } from "../BaseDayTimeInput";
import { FormInputWrapper } from "../FormInputWrapper";

export const BaseDateDurationInput = ({
  name,
  control,
  label,
  width = 200,
  size,
  layoutType = "default",
  helperText,
  errors,
  disabledStartDate,
  disabledEndDate
}: BaseDateDurationInputProps<FieldValues>) => {
  return (
    <FormInputWrapper
      layoutType={layoutType}
      fullWidth={true}
      width={width}
      label={label}
      size={size}
      helperText={helperText}
    >
      <Box sx={{ width: "100%" }}>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <BaseDayTimeInput
            name={`${name}[0]`}
            control={control}
            label={"시작일"}
            width={width}
            size={size}
            disabled={disabledEndDate}
            error={errors?.[name]?.[0]}
          />
          <BaseDayTimeInput
            name={`${name}[1]`}
            control={control}
            label={"종료일"}
            width={width}
            size={size}
            disabled={disabledStartDate}
            error={errors?.[name]?.[1]}
          />
        </Stack>
      </Box>
    </FormInputWrapper>
  );
};
