import { MouseEvent, useCallback, useState } from "react";

import csv from "csvtojson";

type UseCsvProps = {
  exampleCsvList?: (number | string)[];
  exampleCsvFieldName?: string;
};

const useCsv = (props?: UseCsvProps) => {
  const [csvFile, setCsvFile] = useState<FileList | null>(null);

  async function convertCsvToArray(
    csvStr: string,
    callback?: (result: string[]) => void
  ) {
    return csv({
      noheader: false,
      output: "csv"
    })
      .fromString(csvStr)
      .then((csvRow: string[]) => {
        const phoneNumberRegex = /^\+82\s10[-]\d{4}[-]\d{4}$/;

        const parsedCsv = csvRow
          .filter((r) => {
            // 빈 문자열 제거
            return r[0];
          })
          .map((r) => {
            // ex) +82 10-0000-0000 형식이라면 010-0000-0000로 변환, 아니라면 그대로
            return phoneNumberRegex.test(r[0]) ? 0 + r[0].substring(4) : r[0];
          });

        callback && callback(parsedCsv);
        return parsedCsv;
      });
  }

  const downloadCsvExample = (e: MouseEvent) => {
    const fieldName = props?.exampleCsvFieldName ?? "Userlist";
    const csvList = props?.exampleCsvList ?? ["010-0000-0000", "010-0000-0001"];
    const encodedUri = encodeURI(
      `data:text/csv;charset=utf-8,${fieldName}\n${csvList.join("\n")}`
    );
    window.open(encodedUri);
  };

  return {
    csvFile,
    setCsvFile,
    downloadCsvExample: useCallback(downloadCsvExample, []),
    convertCsvToArray: useCallback(convertCsvToArray, [])
  };
};

export default useCsv;
