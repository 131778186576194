import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {
  DeepPartial,
  FieldErrors,
  FieldValues,
  useForm
} from "react-hook-form";
import {
  getMsgFromFieldErrors,
  yup,
  getConfirmMessage,
  InputsData
} from "shared-utils";
import { ObjectShape } from "yup/lib/object";

import { BaseAlertDialog, BaseConfirmDialog } from "../dialog";
import { SpecificPaperContainer } from "../specifics";
import { SystemDialogProvider } from "../systemDialogProvider/SystemDialogProvider";
import { BaseInputTypeEnum, FomrGeneratorProps } from "../types";
import { withContextProvider } from "../withContextProvider";

import { useFormGeneratorContext } from "./FormGeneratorProvider";
import { InputRenderer } from "./InputRenderer";
import { useSystemDialog } from "./useSystemDialog";

/**
 * @description 인풋들의 정보가 담긴 상수 inputInfos 값으로, form을 만들어주는 컴포넌트입니다. react-hook-form을 사용합니다.
 * @param inputInfos 랜더링할 인풋들의 정보 - name, label, defaultValue, validation, inputType, size, layoutType, childInputInfos 등
 * @param filterInputInfos 필터 인풋들의 정보
 * @param onCreate 생성하기 버튼 클릭 시 실행할 함수
 * @param onEdit 수정하기 버튼 클릭 시 실행할 함수
 * @param onSubmit 생성과 수정기능이 함수에 모두 포함되어 있다면, onCreate, onEdit 대신 onSubmit으로 대체 가능
 * @param onDelete 삭제하기 버튼 클릭 시 실행할 함수
 * @param onCancel 취소하기 버튼 클릭 시 실행할 함수
 * @param onError 에러시 기본적으로 필드의 에러메세지를 alert로 띄웁니다. 이와 다른 에러 핸들링이 필요하다면 추가하는 함수
 * @param title 상단에 표시될 제목
 * @param mode create, edit 중 하나로, 생성하기 버튼과 수정하기 버튼의 텍스트를 결정
 * @param elevation Paper의 elevation
 * @param inputOptions 각 필드에 대한 name, label 값을 가진 객체
 * @param closeDialog dialog를 닫는 함수
 * @param hasSubmitButton submit 버튼을 띄울지 말지 결정
 * @param editActionText 수정하기 버튼의 텍스트
 * @param createActionText 생성하기 버튼의 텍스트
 * @param deleteActionText 삭제하기 버튼의 텍스트
 * @param p container의 패딩값
 * @param spacing stack 사이 간격
 */
export const FormGeneratorInner = <TFieldValues extends FieldValues>(
  props: FomrGeneratorProps<TFieldValues>
) => {
  const {
    inputInfos,
    onCreate,
    onEdit,
    onSubmit,
    onDelete,
    onCancel,
    onError,
    title,
    mode,
    elevation,
    setShouldConfirmChanges,
    closeDialog,
    hasSubmitButton = true,
    editActionText = "수정하기",
    createActionText = "추가하기",
    deleteActionText = "삭제하기",
    submitButtonSx = {},
    p = 2,
    spacing = 2
  } = props;

  const {
    systemConfirm,
    systemAlert,
    open,
    title: dialogTitle,
    content,
    onCancel: onDialogCancel,
    onConfirm: onDialogConfirm
  } = useSystemDialog();

  const defaultValues = inputInfos?.reduce((acc, inputInfo) => {
    acc[inputInfo.name as keyof TFieldValues] = inputInfo.defaultValue;
    // childInputInfos 가 있는 경우
    if (
      inputInfo.inputType === BaseInputTypeEnum.DropdownInputGroup &&
      inputInfo.childInputInfos
    ) {
      inputInfo.childInputInfos.forEach((childInputInfo) => {
        acc[childInputInfo.name as keyof TFieldValues] =
          childInputInfo.defaultValue;
      });
    }
    return acc;
  }, {} as TFieldValues) as DeepPartial<TFieldValues>;

  const inputOptions = inputInfos?.reduce((acc, inputInfo) => {
    // confirm message 출력을 위한 name, label, options 값 추출
    acc[inputInfo.name as string] = {
      name: inputInfo.name,
      label: inputInfo.label,
      options: "options" in inputInfo ? inputInfo.options : undefined
    };

    // childInputInfos 가 있는 경우, childInputInfos의 name, label, options 값 추출
    if (
      inputInfo.inputType === BaseInputTypeEnum.DropdownInputGroup &&
      inputInfo.childInputInfos
    ) {
      inputInfo.childInputInfos.forEach((childInputInfo) => {
        acc[childInputInfo.name as string] = {
          name: childInputInfo.name,
          label: childInputInfo.label,
          options: "options" in inputInfo ? inputInfo.options : undefined
        };
      });
    }
    return acc;
  }, {}) as InputsData;

  const schema = yup.object(
    inputInfos.reduce((acc, inputInfo) => {
      if (inputInfo.validation) {
        acc[inputInfo.name] = inputInfo.validation.label(inputInfo.label);
      }

      // childInputInfos 가 있는 경우
      if (
        inputInfo.inputType === BaseInputTypeEnum.DropdownInputGroup &&
        inputInfo.childInputInfos
      ) {
        inputInfo.childInputInfos.forEach((childInputInfo) => {
          if (childInputInfo.validation) {
            acc[childInputInfo.name] = childInputInfo.validation.label(
              childInputInfo.label
            );
          }
        });
      }
      return acc;
    }, {} as ObjectShape)
  );

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    getValues,
    setValue,
    reset
  } = useForm<TFieldValues>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)
  });

  const context = useFormGeneratorContext();

  useEffect(() => {
    if (context === undefined) return; // 상위에 Provider가 없는 경우 early return 하여, context 값을 업데이트 하지 않는다.
    const [_, setValue] = context;
    setValue((prev) => ({
      ...prev,
      getValues,
      errors
    }));
  }, [getValues, errors]);

  const resetForm = () => {
    reset(defaultValues, { keepDirty: false, keepErrors: false });
  };

  const hasSubmitFn = onSubmit || onCreate || onEdit;

  const onSubmitHandler = async (data: TFieldValues) => {
    console.log("data", data);
    const descriptionText =
      mode === "create" ? "추가하시겠습니까?" : "변경하겠습니까?";

    const confirmed =
      inputOptions &&
      (await systemConfirm(
        getConfirmMessage(data, inputOptions, title, descriptionText)
      ));

    if (!confirmed) return;
    if (onSubmit) {
      // onSubmit에 수정하기, 생성하기 기능이 표함되어 있는 경우
      onSubmit(data, {
        onSuccess: resetForm
      });
    }

    if (mode === "create" && onCreate) {
      onCreate(data, {
        onSuccess: () => {
          resetForm();
          closeDialog && closeDialog();
        }
      });
    }

    if (mode === "edit" && onEdit) {
      onEdit(data); // 데이터 보여주기 위해 reset 하지 않음
      closeDialog && closeDialog();
    }
  };

  const actionText = mode === "create" ? createActionText : editActionText;

  const onErrorHandler = async (errors: FieldErrors<TFieldValues>) => {
    if (onError) {
      onError(errors);
    } else {
      const message = getMsgFromFieldErrors(errors);
      await systemAlert(message);
    }
  };

  const onDeleteHandler = async () => {
    if (!onDelete) return;

    const data = getValues();
    const confirmed = await systemConfirm(
      inputOptions &&
        getConfirmMessage(data, inputOptions, title, "삭제하겠습니까?")
    );
    if (!confirmed) return;

    onDelete(data);
  };

  useEffect(() => {
    // if dirtyFields is not empty, setShouldConfirmChanges to true
    if (setShouldConfirmChanges && Object.keys(dirtyFields).length > 0) {
      setShouldConfirmChanges(true);
    }
  }, [dirtyFields]);

  const SubmitFn = hasSubmitFn
    ? handleSubmit(onSubmitHandler, onErrorHandler)
    : (e) => {
        // onSubmit이 없는 경우, form의 기본 동작을 막기 위해 빈 함수를 넣어준다.
        e.preventDefault();
      };

  return (
    <>
      <SpecificPaperContainer title={title} elevation={elevation} p={p}>
        <Stack onSubmit={SubmitFn} component="form" spacing={spacing}>
          {onDelete && (
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                color="error"
                onClick={onDeleteHandler}
              >
                {deleteActionText}
              </Button>
            </Stack>
          )}

          <InputRenderer<TFieldValues>
            inputInfos={inputInfos}
            errors={errors}
            control={control}
            setValue={setValue}
          />

          {
            <Stack direction="row" justifyContent="end" gap={2}>
              {onCancel ? (
                <Button onClick={onCancel} variant="outlined">
                  취소
                </Button>
              ) : null}
              {hasSubmitButton ? (
                <Button type="submit" variant="contained" sx={submitButtonSx}>
                  {actionText}
                </Button>
              ) : null}
            </Stack>
          }
        </Stack>
      </SpecificPaperContainer>

      <BaseConfirmDialog
        open={open === "confirm"}
        title={dialogTitle}
        content={content}
        onCancel={onDialogCancel}
        onConfirm={onDialogConfirm}
      ></BaseConfirmDialog>
      <BaseAlertDialog
        open={open === "alert"}
        title={dialogTitle}
        content={content}
        onConfirm={onDialogCancel}
      ></BaseAlertDialog>
    </>
  );
};

/**
 * @description FormGenerator 컴포넌트를 사용하기 위해 Provider로 감싸주는 컴포넌트입니다.
 * @see FormGeneratorInner
 * @see SystemDialogProvider
 * @see withContextProvider
 */
export const FormGenerator = withContextProvider(
  SystemDialogProvider,
  FormGeneratorInner
) as typeof FormGeneratorInner;
