import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { VerticalInputLabelProps } from "../../types";

export function VerticalInputLabel({
  children,
  size
}: VerticalInputLabelProps) {
  return (
    <StyledLabel component="label" size={size}>
      {children}
    </StyledLabel>
  );
}

type StyledProps = { size: VerticalInputLabelProps["size"] };

const StyledLabel = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "size" && prop !== "fullWidth" && prop !== "width"
})<StyledProps>(({ theme, size }) => ({
  background: "transparent",
  textAlign: "left",
  padding:
    size === "small"
      ? `${theme.spacing(1)}`
      : `${theme.spacing(2)}  ${theme.spacing(1)}`,
  width: "25%",
  maxWidth: "170px",
  minWidth: "100px",
  wordBreak: "break-all",
  height: "fit-content",
  flexGrow: 1,
  flexShrink: 0,
  borderRadius: "3px"
}));
