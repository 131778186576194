import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Controller, FieldValues, Path } from "react-hook-form";

import { BaseInputProps } from "../../types";
import { FormInputWrapper } from "../FormInputWrapper/FormInputWrapper";

export function BaseInput<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  fullWidth,
  width = 200,
  size,
  layoutType = "default",
  disabled = false,
  helperText,
  type,
  error,
  multiline = false,
  placeholder,
  rows = 3,
  InputProps
}: BaseInputProps<TFieldValues>) {
  return (
    <FormInputWrapper
      layoutType={layoutType}
      fullWidth={fullWidth}
      width={width}
      label={label}
      size={size}
    >
      <Box sx={{ width: fullWidth ? "100%" : `${width ?? 200}px` }}>
        <Controller
          name={name as Path<TFieldValues>}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              fullWidth
              multiline={multiline}
              minRows={multiline ? 3 : undefined}
              disabled={disabled}
              size={size}
              type={type}
              label={layoutType === "default" ? label : undefined}
              error={error ? true : false}
              helperText={error?.message ?? helperText}
              InputProps={InputProps}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              placeholder={placeholder}
              rows={rows}
            />
          )}
        />
      </Box>
    </FormInputWrapper>
  );
}
