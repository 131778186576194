import { ReactElement } from "react";

import { SxProps } from "@mui/material";
import Paper from "@mui/material/Paper";

function SpecificPaperContainer({
  children,
  title,
  elevation = 2,
  p,
  sx
}: {
  children: ReactElement;
  title?: string;
  elevation?: number;
  p?: number;
  sx?: SxProps;
}) {
  return (
    <Paper sx={{ p: p ?? 1, my: 1, ...sx }} elevation={elevation}>
      {title && <h3>{title}</h3>}
      {children}
    </Paper>
  );
}

export default SpecificPaperContainer;
