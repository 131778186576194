import { useEffect } from "react";

import NaverRidingMap from "shared-utils";

import { Geoblock, MapRiding, MapRidingTrackPoint, Position } from "./types";

const useRidingPathMap = (elementId: string, mapInstance: NaverRidingMap) => {
  const useInitializeMap = () => {
    useEffect(() => {
      mapInstance.setMap(elementId);
      mapInstance.closeWindowByClickMapEvent(); // 마커 아닌 지도 구역 클릭시, window를 닫기 위한 이벤트 추가

      return () => mapInstance.destroy();
    }, []);
  };

  const useUpdateStartPoint = (riding: MapRiding | null) => {
    useEffect(() => {
      riding?.start_point &&
        mapInstance.setStartPointMarker(riding?.start_point);
    }, [riding?.start_point]);
  };

  const useUpdateEndPoint = (riding: MapRiding | null) => {
    useEffect(() => {
      mapInstance.removeEndPointMarker();
      riding?.end_point && mapInstance.setEndPointMarker(riding?.end_point);
    }, [riding?.end_point]);
  };

  const useUpdateRidingPath = (paths: Position[]) => {
    useEffect(() => {
      mapInstance.removeRidingPathPolyline();
      mapInstance.setRidingPathPolyline(paths);
    }, [paths]);
  };

  const useUpdateCenterPosition = (riding: MapRiding | null) => {
    useEffect(() => {
      if (!(riding?.start_point || riding?.end_point)) return;

      mapInstance.setCenter(riding?.end_point || riding?.start_point);
    }, [riding?.end_point, riding?.start_point]);
  };

  const useUpdateTrackPoints = (trackPoints: MapRidingTrackPoint[]) => {
    useEffect(() => {
      mapInstance.removeAllTrackPointsMarkers();
      if (trackPoints.length === 0) return;
      mapInstance.setTrackPointsWindowMarkers(trackPoints);
    }, [trackPoints]);
  };

  const onFocusTrackPoint = (_id: string, center: Position) => {
    mapInstance.openTrackPointInfoWindow(_id);
    mapInstance.setCenter(center);
  };

  const updateGeoblock = (geoblocks: Geoblock[]) => {
    useEffect(() => {
      mapInstance.removeAllGeoblocks();
      mapInstance.setGeoblocks(geoblocks);
    }, [geoblocks]);
  };

  return {
    useInitializeMap,
    useUpdateStartPoint,
    useUpdateEndPoint,
    useUpdateRidingPath,
    useUpdateCenterPosition,
    useUpdateTrackPoints,
    onFocusTrackPoint,
    updateGeoblock
  };
};

export default useRidingPathMap;
