import { useEffect, useState } from "react";

import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import { FieldValues } from "react-hook-form";

import { BaseSubcategoryCheckboxGroupProps } from "../../types";
import { FormInputWrapper } from "../FormInputWrapper/FormInputWrapper";

import CategoryToggleGroup from "./CategoryToggleGroup";
import SubcategoryCheckBox from "./SubcategoryCheckBox";
import SubcategoryChip from "./SubcategoryChip";

function BaseSubcategoryCheckboxGroup<T extends FieldValues>({
  control,
  categories,
  subcategories,
  placeholder,
  categoryWatch,
  subcategoryWatch,
  setValue,
  layoutType = "default",
  disabled = false,
  size = "medium",
  label,
  name,
  title,
  fullWidth = true,
  resetField
}: BaseSubcategoryCheckboxGroupProps<T>) {
  const [filteredSubcategory, setFilteredSubcategory] = useState<any[]>([]);

  useEffect(() => {
    const newFilteredList = subcategories
      .filter((sub) => sub.category === categoryWatch)
      .map((filtered) => filtered.value);
    setFilteredSubcategory(newFilteredList);
  }, [categoryWatch]);

  return (
    <>
      <FormInputWrapper
        layoutType={layoutType}
        label={label}
        size={size}
        fullWidth={fullWidth}
      >
        <Box sx={{ width: fullWidth ? "100%" : "auto" }}>
          {title ? <Box sx={{ m: 1 }}>{title}</Box> : null}
          <StyledCheckboxGroupWarpper>
            <CategoryToggleGroup
              fullWidth
              categories={categories}
              control={control}
              disabled={disabled}
              name={name?.category}
            />
            <SubcategoryCheckBox
              control={control}
              subcategories={subcategories}
              filteredList={filteredSubcategory}
              placeholder={placeholder}
              disabled={disabled}
              name={name?.subcategory}
            />
          </StyledCheckboxGroupWarpper>
          <SubcategoryChip
            subcategories={subcategories}
            subcategoryWatch={subcategoryWatch}
            setValue={setValue}
            disabled={disabled}
            name={name?.subcategory}
            resetField={resetField}
          />
        </Box>
      </FormInputWrapper>
    </>
  );
}

const StyledCheckboxGroupWarpper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`
}));

export default BaseSubcategoryCheckboxGroup;
