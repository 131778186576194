import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path
} from "react-hook-form";

import { BaseMultipleSelectProps } from "../../types";
import { FormInputWrapper } from "../FormInputWrapper/FormInputWrapper";

export function BaseMultipleSelect<TFieldValues extends FieldValues>({
  options,
  name,
  control,
  label,
  fullWidth,
  width = 200,
  size,
  layoutType = "default",
  disabled = false,
  helperText,
  error,
  hasCheckAll = true,
  setValue
}: BaseMultipleSelectProps<TFieldValues>) {
  const renderMultipleValues = (selected) => {
    const selectedOptions = options?.filter((select) =>
      selected.includes(select.value)
    );
    return selectedOptions?.map((item) => item.label).join(", ");
  };

  const checkAllOptions = (field: ControllerRenderProps<any, any>) => {
    if (setValue) {
      field.value.length === options.length
        ? setValue(name, [], { shouldValidate: true })
        : setValue(
            name,
            options.map((option) => option.value),
            { shouldValidate: true }
          );
    }
  };

  return (
    <FormInputWrapper
      layoutType={layoutType}
      fullWidth={fullWidth}
      width={width}
      label={label}
      size={size}
    >
      <>
        {layoutType === "default" && <InputLabel>{label}</InputLabel>}

        <Box sx={{ width: fullWidth ? "100%" : `${width}px` }}>
          <Controller
            name={name as Path<TFieldValues>}
            render={({ field }) => (
              <Select
                {...field}
                size={size}
                fullWidth
                disabled={disabled}
                multiple
                renderValue={renderMultipleValues}
                error={error ? true : false}
                label={layoutType === "default" ? label : undefined}
              >
                {hasCheckAll && (
                  <FormControlLabel
                    sx={{
                      paddingLeft: 3.5
                    }}
                    control={
                      <Controller
                        name={"checkAll" as Path<TFieldValues>}
                        control={control}
                        render={({ field: props }) => (
                          <Checkbox
                            {...props}
                            disabled={disabled}
                            checked={field.value.length === options.length}
                            onChange={() => checkAllOptions(field)}
                          />
                        )}
                      />
                    }
                    label={"전체"}
                  />
                )}

                {options?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Checkbox checked={field.value?.includes(item.value)} />
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
            control={control}
          />
          {error?.message && (
            <FormHelperText error>{error?.message}</FormHelperText>
          )}

          {!error?.message && helperText && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
        </Box>
      </>
    </FormInputWrapper>
  );
}
