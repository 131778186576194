import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const StyledThumnailImageWrapper = styled(Box)(() => ({
  border: "1px solid #ddd",
  display: "flex",
  height: "100%",
  position: "relative",
  width: "fit-content"
}));

export const StyledZoomInIcon = styled(ZoomInIcon)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  color: theme.palette.grey[700],
  fontSize: 16,
  cursor: "pointer",
  background: "white"
}));

export const StyledLoadingWrapper = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)"
}));
