import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { FieldValues } from "react-hook-form";

import { BaseTimeDurationInputProps } from "../../types";
import { BaseTimeInput } from "../BaseTimeInput";
import { FormInputWrapper } from "../FormInputWrapper";

export const BaseTimeDurationInput = <T extends FieldValues>({
  name,
  control,
  label,
  width = 200,
  size,
  layoutType = "default",
  helperText,
  errors,
  disabledStartTime,
  disabledEndTime
}: BaseTimeDurationInputProps<T>) => {
  return (
    <FormInputWrapper
      layoutType={layoutType}
      fullWidth={true}
      width={width}
      label={label}
      size={size}
      helperText={helperText}
    >
      <Box sx={{ width: "100%" }}>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <BaseTimeInput
            name={`${name}[0]`}
            control={control}
            label={"시작 시간"}
            width={width}
            size={size}
            disabled={disabledEndTime}
            error={errors?.[name]?.[0]}
          />
          <BaseTimeInput
            name={`${name}[1]`}
            control={control}
            label={"종료 시간"}
            width={width}
            size={size}
            disabled={disabledStartTime}
            error={errors?.[name]?.[1]}
          />
        </Stack>
      </Box>
    </FormInputWrapper>
  );
};
