import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";

import { FromInputWrapperProps } from "../../types";
import { HorizontalInputLabel } from "../HorizontalInputLabel";
import { VerticalInputLabel } from "../VerticalInputLabel";

export function FormInputWrapper({
  layoutType = "default",
  fullWidth,
  children,
  size,
  label,
  width,
  error,
  helperText
}: FromInputWrapperProps) {
  const errorMessage = Array.isArray(error)
    ? error[0]?.message
    : error?.message;

  return (
    <StyledFormWrapper
      layoutType={layoutType}
      fullWidth={fullWidth}
      size={size}
    >
      {layoutType === "horizontal" && (
        <HorizontalInputLabel size={size}>{label}</HorizontalInputLabel>
      )}
      {layoutType === "vertical" && (
        <VerticalInputLabel size={size}>{label}</VerticalInputLabel>
      )}
      <StyledInputWrapper
        sx={{ width: fullWidth ? "100%" : width ? width + "px" : "auto" }}
      >
        {children}

        {errorMessage || helperText ? (
          <FormHelperText error={error ? true : false}>
            {errorMessage ?? helperText ?? ""}
          </FormHelperText>
        ) : null}
      </StyledInputWrapper>
    </StyledFormWrapper>
  );
}

type StyledProps = Pick<
  FromInputWrapperProps,
  "fullWidth" | "layoutType" | "width"
>;

const StyledFormWrapper = styled(FormControl, {
  shouldForwardProp: (prop) =>
    prop !== "layoutType" && prop !== "fullWidth" && prop !== "width"
})<StyledProps>(({ theme, layoutType, fullWidth }) => ({
  display: "flex",
  flexDirection: layoutType === "horizontal" ? "row" : "column",
  gap: layoutType === "vertical" ? 0 : theme.spacing(2),
  width: fullWidth === true ? "100%" : "inherit",
  alignItems: "start",
  ".MuiInputBase-input, .MuiInputBase-root": {
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5)
  },
  // media query sm match
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

const StyledInputWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap"
}));
