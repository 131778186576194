import { useState, useEffect, useCallback } from "react";

import { useRouter } from "next/router";

import { PaginationMeta } from "../table/TablePagination";

const usePagination = (count = 0, f?: (query: any) => void) => {
  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>({
    currentPage: 1,
    pageLength: 1
  });
  const router = useRouter();

  const handleChangePagination = useCallback(
    ({ page }: { page: number }) => {
      const query = router.query;
      router.push({ query: { ...query, page } });
    },
    [router]
  );

  const onPageChanged = (page: number) => {
    handleChangePagination({ page });
  };

  useEffect(() => {
    f && f(router.query.page);
    setPaginationMeta({
      currentPage: Number(router.query.page ?? 1),
      pageLength: Math.ceil(Number(count) / 20)
    });
  }, [router.query, count, f]);

  return {
    paginationMeta,
    onPageChanged: useCallback(onPageChanged, [handleChangePagination])
  };
};

export default usePagination;
