import { ReactElement, useState } from "react";

import { grey } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useRouter } from "next/router";

import BaseChip from "../BaseChip/BaseChip";

export type BaseChipMenu = {
  handleClick?: () => void;
  href?: string;
  text: string;
  icon?: ReactElement;
};

export type BaseChipProps = {
  label: string;
  items: BaseChipMenu[][];
  color?: "lightBlue" | "red" | "green" | "orange" | "purple" | "pink" | "grey";
  width?: number | string;
  statusIcon?: boolean;
};

export function BaseChipMenu({
  label,
  items,
  color,
  width,
  statusIcon
}: BaseChipProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickChip = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const router = useRouter();

  const onClickMenu = (menu: BaseChipMenu) => {
    handleCloseMenu();
    menu.handleClick && menu.handleClick();
  };

  return (
    <div>
      <BaseChip
        label={label}
        color={color}
        onClick={handleClickChip}
        menuIcon
        width={width}
        statusIcon={statusIcon}
      />

      <Menu
        id="chip-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={open}
        onClose={handleCloseMenu}
      >
        <MenuList sx={{ padding: 0 }} dense>
          {items.map((menuGroup, idx) => (
            <div key={idx}>
              {menuGroup.map((menu) => (
                <div key={menu.text}>
                  <Link
                    onClick={() => onClickMenu(menu)}
                    href={menu.href}
                    sx={{ color: grey[800], textDecoration: "none" }}
                  >
                    <MenuItem onClick={handleCloseMenu}>
                      {menu.icon && <ListItemIcon>{menu.icon}</ListItemIcon>}
                      <ListItemText>{menu.text}</ListItemText>
                    </MenuItem>
                  </Link>
                </div>
              ))}
              {idx < items.length - 1 && <Divider sx={{ mt: 1, mb: 1 }} />}
            </div>
          ))}
        </MenuList>
      </Menu>
    </div>
  );
}
