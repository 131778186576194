import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";

export const BaseDialogActions = ({ children }) => {
  return (
    <DialogActions>
      <Stack direction="row" sx={{ width: "100%", p: 1 }} gap={2}>
        {children}
      </Stack>
    </DialogActions>
  );
};
