import { ReactNode, useEffect, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import {
  StyledArrowIcon,
  StyledBoardStack,
  StyledInfoBoard,
  StyledInfoToggleButton
} from "./styles";

const MapSideBar = ({
  openDependency = [],
  isToolge = true,
  children
}: {
  openDependency?: any[];
  isToolge?: boolean;
  children: ReactNode;
}) => {
  const [open, setOpen] = useState(true);
  const toggleOpen = () => setOpen((prevOpen) => !prevOpen);
  const matches = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    setOpen(true);
  }, [...openDependency]);

  useEffect(() => {
    if (isToolge && matches) {
      setOpen(false);
    }
  }, [matches]);
  return (
    <StyledInfoBoard open={open} elevation={3}>
      <StyledBoardStack open={open} spacing={2}>
        {children}
      </StyledBoardStack>

      {isToolge && (
        <StyledInfoToggleButton
          className="toggle-botton"
          role="button"
          onClick={toggleOpen}
        >
          <StyledArrowIcon open={open} />
        </StyledInfoToggleButton>
      )}
    </StyledInfoBoard>
  );
};

export default MapSideBar;
