import { ReactElement } from "react";

import type { LayoutProps } from "types/pageWithLayouts.types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

interface Props {
  children: ReactElement;
}

const SiteLayout: LayoutProps = ({ children }: Props) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CssBaseline />
      {children}
    </Box>
  );
};

export const getLayout = (page: ReactElement) => (
  <SiteLayout>{page}</SiteLayout>
);

export default SiteLayout;
