import { useCallback, useRef, useState } from "react";
import { useMemo } from "react";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import {
  GridRenderCellParams,
  GridSelectionModel,
  GridValidRowModel
} from "@mui/x-data-grid";
import { useRouter } from "next/router";

import { PaginationMeta, TablePagination } from "../TablePagination";

import { defaultColumnOptions } from "./constants";
import { ErrorOccurred } from "./ErrorOccurred";
import { NoRows } from "./NoRows";
import {
  dynamicHeightStyle,
  PinButtonWrapper,
  PinnableHeader,
  StyledBaseTableContainer,
  StyledListDataGrid
} from "./styles";
import { BaseTableColumns } from "./types";

type Props<T extends GridValidRowModel> = {
  isLoading?: boolean;
  rows?: GridValidRowModel[];
  columns: BaseTableColumns<T>;
  error?: { message?: string; type?: string } | any;
  paginationMeta?: PaginationMeta;
  selectionMode?: boolean;
  onSelect?: (id: GridSelectionModel) => void;
  dymanicRowHeight?: boolean;
  rowHeight?: number;
  elevation?: number;
};

export function BaseTable<T extends GridValidRowModel>({
  isLoading,
  rows = [],
  columns,
  error,
  paginationMeta,
  selectionMode = false,
  onSelect,
  rowHeight,
  elevation = 1
}: Props<T>) {
  const router = useRouter();
  const pinnableColumn = useRef<any>();

  const handlePageChange = (page: number) => {
    const query = router.query;
    router.push(
      {
        query: {
          ...query,
          page: page
        }
      },
      undefined,
      {
        shallow: true
      }
    );
  };
  const theme = useTheme();
  const generateDefaultPin = useCallback((column) => {
    if (!column.pinnable) return column;
    return {
      ...column,
      width: column.width || 100,
      maxWidth: column.width || 100,
      minWidth: column.width || 100,
      headerClassName: "sticky_header",
      cellClassName: "sticky",
      renderCell: (params: GridRenderCellParams) => {
        const renderElement = column.renderCell ? (
          column.renderCell(params)
        ) : (
          <PinButtonWrapper>
            <Button
              color="primary"
              variant="text"
              size="small"
              sx={{
                backgroundColor: theme.palette.primary.extraLight
              }}
              onClick={() => column.onPinnedClick(params)}
            >
              {column.headerName}
            </Button>
          </PinButtonWrapper>
        );
        return renderElement;
      }
    };
  }, []);

  const hasPinnable = useMemo(
    () => columns.some((column) => column.pinnable),
    [columns]
  );

  const [internalError, setInternalError] = useState();

  const columnsWithDetaults = columns.map((column) => {
    let defaultColumn = { ...column };
    if (column.pinnable) {
      defaultColumn = generateDefaultPin(column);
      pinnableColumn.current = defaultColumn;
    }
    return { ...defaultColumnOptions, ...defaultColumn };
  });

  return (
    <div>
      <Paper elevation={elevation}>
        <StyledBaseTableContainer>
          {pinnableColumn && !internalError && (
            <PinnableHeader width={pinnableColumn?.current?.width}>
              {pinnableColumn?.current?.headerName}
            </PinnableHeader>
          )}
          <StyledListDataGrid
            className={hasPinnable ? "hasPinnable" : ""}
            autoHeight={true}
            rows={rows}
            columns={columnsWithDetaults}
            disableSelectionOnClick
            loading={isLoading}
            error={error}
            components={{
              Pagination: paginationMeta ? TablePagination : null,
              NoRowsOverlay: NoRows,
              ErrorOverlay: ErrorOccurred
            }}
            componentsProps={{
              pagination: {
                currentPage: paginationMeta?.currentPage,
                pageLength: paginationMeta?.pageLength,
                onPageChanged: handlePageChange
              }
            }}
            hideFooter={!paginationMeta}
            checkboxSelection={selectionMode}
            onSelectionModelChange={onSelect}
            getRowHeight={rowHeight ? () => rowHeight : () => "auto"}
            sx={dynamicHeightStyle}
            onError={(e) => setInternalError(e)}
          />
        </StyledBaseTableContainer>
      </Paper>
    </div>
  );
}
