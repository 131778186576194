import { useState } from "react";
import type { ReactNode } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

interface HistoryDropdownProps {
  title?: string;
  getList: () => void;
  children?: ReactNode;
  loading?: boolean;
}

export const HistoryDropdown = ({
  title = "2년내 최근 10개", // 서버에서 히스토리의 경우 2년 내로 제한되어 있음 (아닌 경우 title prop 필요)
  getList,
  children,
  loading
}: HistoryDropdownProps) => {
  const [isTableOpen, setIsTableOpen] = useState(false);

  const handleClick = () => {
    if (isTableOpen) {
      setIsTableOpen(false);
    } else {
      setIsTableOpen(true);
      getList();
    }
  };

  if (loading)
    return (
      <Stack>
        <CircularProgress size={25} sx={{ margin: "auto" }} />
      </Stack>
    );

  return (
    <Stack>
      <Button variant="text" onClick={handleClick}>
        {isTableOpen ? (
          "닫기"
        ) : (
          <Stack direction="row">
            <Box>{title}</Box>
            <KeyboardArrowDownIcon />
          </Stack>
        )}
      </Button>
      {(isTableOpen && children) ?? "이력이 없습니다."}
    </Stack>
  );
};
