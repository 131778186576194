import { useState } from "react";

import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import { BaseDialogActions } from "../BaseDialog/BaseDialogActions";
import {
  StyledButton,
  StyledDialogContainer,
  StyledDialogContent
} from "../styles";

export function BasePromptDialog({ open, title, onConfirm, onCancel }) {
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <StyledDialogContainer open={open} maxWidth="sm">
      <DialogTitle sx={{ fontSize: "16px", fontWeight: 700 }}>
        {title}
      </DialogTitle>
      <StyledDialogContent>
        <TextField onChange={handleChange} fullWidth size="small" autoFocus />
      </StyledDialogContent>
      <BaseDialogActions>
        <StyledButton variant="outlined" onClick={onCancel}>
          취소
        </StyledButton>
        <StyledButton variant="contained" onClick={() => onConfirm(value)}>
          확인
        </StyledButton>
      </BaseDialogActions>
    </StyledDialogContainer>
  );
}
