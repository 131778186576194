import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

type StyledInfoBoardProps = {
  open: boolean;
};

export const StyledBoardStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "open"
})<StyledInfoBoardProps>(({ theme, open }) => ({
  maxHeight: "100%",
  visibility: open ? "visible" : "hidden",
  padding: theme.spacing(1),
  overflowY: "scroll"
}));

export const StyledInfoBoard = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "open"
})<StyledInfoBoardProps>(({ theme, open }) => ({
  position: "absolute",
  height: "100%",
  width: open ? "500px" : 0,
  maxWidth: "30%",
  top: 0,
  left: 0,
  borderRadius: "0 8px 8px 0",
  minWidth: open ? "300px" : 0,
  lineHeight: 1.7,
  backgroundColor: theme.palette.grey[50],
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    bottom: 0,
    top: "auto",
    maxWidth: "100%",
    height: open ? "40%" : 0,
    borderRadius: "8px 8px 0 0",
    ".toggle-botton": {
      top: "-32px",
      right: "50%",
      transform: `translate(50%, 0) rotate(${270}deg)`
    }
  }
}));

export const StyledInfoToggleButton = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  right: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.grey[700],
  transform: "translateY(-50%) translateX(25px)",
  width: "25px",
  height: "40px",
  cursor: "pointer",
  backgroundColor: theme.palette.grey[100],
  borderRadius: "0 4px 4px 0",
  border: `1px solid ${theme.palette.grey[300]}}`
}));

export const StyledArrowIcon = styled(ArrowForwardIosIcon, {
  shouldForwardProp: (prop) => prop !== "open"
})<StyledInfoBoardProps>(({ open }) => ({
  transform: open ? "rotate(180deg)" : "rotate(0deg)"
}));
