import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export const StyledToggleGroupWrapper = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    "&.MuiToggleButtonGroup-root": {
      width: "100%",
      border: `1px solid ${theme.palette.grey[300]}`,
      borderBottom: "none",
      borderRadius: 0
    },
    ".MuiToggleButton-root": {
      flexGrow: 1,
      border: "none",
      borderBottom: "3px solid transparent",

      "&.Mui-selected": {
        backgroundColor: "transparent",
        borderBottom: `3px solid ${theme.palette.primary.light}}`,
        borderRadius: "0px"
      }
    }
  })
);

type StyledCheckboxContainerBoxProps = {
  error?: boolean;
};
export const StyledCheckboxContainerBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "error"
})<StyledCheckboxContainerBoxProps>(({ theme, error }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${
    error ? theme.palette.error.main : theme.palette.grey[300]
  }`,
  width: "100%"
}));

export const StyledHelperTextContainerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(2)
}));

export const StyledChipContainerStack = styled(Stack)(({ theme }) => ({
  borderRadius: 1,
  padding: 2,
  border: `1px solid ${theme.palette.grey[300]}`,
  width: "100%"
}));
