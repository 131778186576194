import { useEffect, useState } from "react";

import AutorenewIcon from "@mui/icons-material/Autorenew";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import Tooltip from "@mui/material/Tooltip";
import { FieldValues, Path, useController } from "react-hook-form";

import { BaseAreaSelectProps } from "../../types";
import { BaseCheckboxGroup } from "../BaseCheckboxGroup";
import { FormInputWrapper } from "../FormInputWrapper";

import {
  StyledCheckboxContainerBox,
  StyledChipContainerStack,
  StyledHelperTextContainerBox,
  StyledToggleGroupWrapper
} from "./styled";

export const BaseAreaSelect = <T extends FieldValues>({
  layoutType = "horizontal",
  label = "지역",
  size,
  name = "area" as Path<T>,
  control,
  setValue,
  areas = [],
  regions = [],
  disabled,
  error
}: BaseAreaSelectProps<T>) => {
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null);

  const areasFilterBySelectedRegion = areas.filter(
    (area) => area.region === selectedRegion
  );

  const { field } = useController({
    control,
    name: name as Path<T>
  });

  useEffect(() => {
    if (areas.length > 0) {
      setSelectedRegion(areas[0].region);
    }
  }, [areas, regions]);

  return (
    <>
      <FormInputWrapper
        layoutType={layoutType}
        label={label}
        size={size}
        fullWidth
      >
        <StyledToggleGroupWrapper
          fullWidth
          disabled={disabled}
          exclusive
          onChange={(_: React.MouseEvent<HTMLElement>, value: string) => {
            setSelectedRegion(value);
          }}
          value={selectedRegion}
        >
          {regions?.map((region) => (
            <ToggleButton key={region.code} value={region.code}>
              {region.name}
            </ToggleButton>
          ))}
        </StyledToggleGroupWrapper>

        <StyledCheckboxContainerBox error={error ? true : false}>
          {selectedRegion ? (
            <BaseCheckboxGroup
              options={areasFilterBySelectedRegion.map((area) => ({
                label: area.name,
                value: area.code
              }))}
              name={name}
              control={control}
              setValue={setValue}
              size={size}
              fullWidth
            />
          ) : (
            <StyledHelperTextContainerBox>
              <FormHelperText>지역을 선택해주세요.</FormHelperText>
            </StyledHelperTextContainerBox>
          )}
        </StyledCheckboxContainerBox>

        {field.value?.length > 0 ? (
          <StyledChipContainerStack mt={2} flexWrap={"wrap"}>
            <Box>
              {field.value.map((areaCode: string) => {
                const area = areas.find((area) => area.code === areaCode);
                return (
                  <Chip
                    key={areaCode}
                    label={area?.name}
                    disabled={disabled}
                    sx={{ ml: 1, mt: 1 }}
                    onDelete={() => {
                      const newValue = field.value?.filter(
                        (value: string) => value !== areaCode
                      );
                      setValue && setValue(name, newValue);
                    }}
                  />
                );
              })}
            </Box>

            <Tooltip title="전체 지역 삭제">
              <IconButton
                sx={{ marginLeft: "auto", mt: 1 }}
                disabled={disabled}
                aria-label="전체 지역 삭제"
                color="primary"
                onClick={() => {
                  setValue && setValue(name, [] as T[Path<T>]);
                }}
              >
                <AutorenewIcon />
              </IconButton>
            </Tooltip>
          </StyledChipContainerStack>
        ) : null}

        <FormHelperText error={error?.message ? true : false}>
          {error?.message}
        </FormHelperText>
      </FormInputWrapper>
    </>
  );
};
