import { Dispatch, createContext, useContext, useState } from "react";

type SpecificDialog = {
  isDialogOpen: boolean;
  dialogType: "create" | "edit";
  selected: any | null;
  shouldConfirmChanges: boolean;
  isCopied: boolean;
  statusActive: boolean;
  isExpired: boolean;
};

type SpecificDialogType =
  | [SpecificDialog, Dispatch<React.SetStateAction<SpecificDialog>>]
  | undefined;

const SpecificDialogContext = createContext<SpecificDialogType>(undefined);

export const SpecificDialogProvider = ({ children }) => {
  const [specificDialog, setSpecificDialog] = useState<SpecificDialog>({
    isDialogOpen: false,
    dialogType: "create",
    selected: null,
    shouldConfirmChanges: false,
    isCopied: false,
    statusActive: false,
    isExpired: false
  });

  return (
    <SpecificDialogContext.Provider value={[specificDialog, setSpecificDialog]}>
      {children}
    </SpecificDialogContext.Provider>
  );
};

export const useSpecificDialogContext = () => {
  const context = useContext(SpecificDialogContext);

  if (!context) {
    throw new Error(
      "useSpecificDialogContext must be used within a SpecificDialogProvider"
    );
  }
  return context;
};
