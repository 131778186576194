import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsBikeOutlinedIcon from "@mui/icons-material/DirectionsBikeOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import LockIcon from "@mui/icons-material/Lock";
import bikeApi from "@network/bike/bikeApi";
import { accountState } from "@store/recoil/accountRecoil";
import { useRecoilValue } from "recoil";
import { BaseChipMenu } from "shared-ui";

function BikeChip({ sn }: { sn: string }) {
  const account = useRecoilValue(accountState);

  async function checkLock() {
    const data = await bikeApi().getRawData(1, {
      n: 1,
      sn
    });

    if (data[0]) {
      alert(data[0].locked == true ? "LOCKED" : "UNLOCKED");
    } else {
      alert("확인할 수 있는 데이터가 없습니다.");
    }
  }

  const menus = [
    [
      {
        text: "잠금상태",
        icon: <LockIcon fontSize="small" />,
        handleClick: () => checkLock()
      }
    ],
    [
      {
        text: "대시보드",
        icon: <DashboardIcon fontSize="small" />,
        href: `/?sn=${sn}`
      }
    ],

    [
      {
        text: "라이딩 / 결제 내역",
        icon: <DirectionsBikeOutlinedIcon fontSize="small" />,
        href: `/riding/list?bike_sn=${sn}`
      }
    ],

    [
      {
        text: "관리내역",
        icon: <ErrorOutlineOutlinedIcon fontSize="small" />,
        href: `/maintenance/list?sn=${sn}`
      },
      {
        text: "기기목록",
        icon: <FormatListBulletedOutlinedIcon fontSize="small" />,
        href: `/bike/list?sn=${sn}`
      },
      {
        text: "고객문의",
        icon: <FormatListBulletedOutlinedIcon fontSize="small" />,
        href: `/inquiry/list?bike_sn=${sn}`
      }
    ]
  ];

  const staffMenus = [
    [
      {
        text: "잠금상태",
        icon: <LockIcon fontSize="small" />,
        handleClick: () => checkLock()
      }
    ]
  ];

  return (
    <BaseChipMenu
      label={sn}
      items={account?.role === "가맹점주" ? menus : staffMenus}
    />
  );
}

export default BikeChip;
