/**
 * @description Provider를 추가하는 HOC입니다.
 * @param Providers 하나 또는 여러개의 Provider를 받습니다.
 * @param Component Provider로 감싸줄 컴포넌트입니다.
 * @returns  Provider로 감싸진 컴포넌트를 반환합니다.
 */
export const withContextProvider = (Providers, Component) => (props) => {
  return (
    <>
      {Array.isArray(Providers) ? (
        Providers.reduceRight((acc, ProviderItem) => {
          return <ProviderItem>{acc}</ProviderItem>;
        }, <Component {...props} />)
      ) : (
        <Providers>{<Component {...props} />}</Providers>
      )}
    </>
  );
};
