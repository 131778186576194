import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { BaseItemColumn, BaseItemTableProps } from "./types";

export function BaseItemTable({
  minWidth = 300,
  maxWidth,
  headerWidth = 120,
  item,
  columns,
  isLoading,
  error,
  options
}: BaseItemTableProps) {
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const getRenderCell = (key: BaseItemColumn<BaseItemTableProps["item"]>) => {
    if (!key.renderCell) return item[key.field];
    return key.renderCell(item);
  };

  useEffect(() => {
    if (options?.hide) {
      const filtered = columns.filter(
        (column) => !options.hide.includes(column.field)
      );
      setVisibleColumns(filtered);
    } else {
      setVisibleColumns(columns);
    }
  }, [options, columns]);

  if (error) {
    return (
      <StyledErrorContainer>
        <StyledTableContainer>에러가 발생했습니다.</StyledTableContainer>
      </StyledErrorContainer>
    );
  }

  return (
    <StyledTableContainer>
      {isLoading && (
        <StyledLoadingContainer>
          <CircularProgress />
        </StyledLoadingContainer>
      )}
      <Table sx={{ minWidth, maxWidth, tableLayout: "fixed" }}>
        <TableBody>
          {visibleColumns.map((key) => (
            <TableRow key={`row_${key.headerName}`}>
              <StyledKeyTableCell
                size="small"
                component="th"
                scope="row"
                sx={{ width: headerWidth }}
              >
                {key.headerName}
              </StyledKeyTableCell>
              <StyledValueTableCell size="small" sx={{ width: "100%" }}>
                <div>{getRenderCell(key)}</div>
              </StyledValueTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}

const StyledTableContainer = styled(TableContainer)(() => ({
  position: "relative",
  "& .MuiTable-root": {
    minWidth: "auto"
  }
}));

const StyledKeyTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#f8f8f8",
  border: "1px solid #d6d6d6",
  verticalAlign: "headerName-top",
  padding: "8px 12px",
  wordBreak: "break-word",
  wordWrap: "initial",
  whiteSpace: "pre-wrap",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100px"
  }
}));

const StyledValueTableCell = styled(TableCell)(() => ({
  border: "1px solid #d6d6d6",
  wordBreak: "keep-all",
  whiteSpace: "break-spaces"
}));

const StyledErrorContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  border: 1px solid #d6d6d6;
  text-align: center;
`;

const StyledLoadingContainer = styled(Box)`
  border: 1px solid #d6d6d6;
  padding: 10px;
  headername-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
