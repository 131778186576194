import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

type CreateButtonProps = {
  onClick: () => void;
};
export const CreateButton = ({ onClick }: CreateButtonProps) => {
  return (
    <Stack direction={"row"} justifyContent={"end"}>
      <Button onClick={onClick} variant="contained">
        추가하기
      </Button>
    </Stack>
  );
};
